import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from 'react-slick';
import '../css/After.css';
import ImageSlider from '@udooku/react-image-comparison-slider';
import img1 from '../images/before01.webp';
import img2 from '../images/after01.webp';
import img3 from '../images/before02.webp'
import img4 from '../images/after02.webp'
import img5 from '../images/before03.webp'
import img6 from '../images/after03.webp'
import img7 from '../images/before04.webp'
import img8 from '../images/after04.webp'
import img9 from '../images/before05.webp'
import img10 from '../images/after05.webp'
import img11 from '../images/before06.webp'
import img12 from '../images/after06.webp'

const After = () => {
  useEffect(() => {
    AOS.init({ duration: 1000,
        once: false, // Animations will only occur once
        mirror: true, // Animation will not be mirrored when scrolling down
    }); // Initialize AOS with a default duration of 1000ms
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className='afterwrap'>
      <div className='afterheadings'>
        <div className='servicescontent' data-aos='fade-up'>
          <p className='explore' style={{ color: '#644222', fontWeight: '1000',fontSize:'2rem' }}>
            The Pleasure Of Beauty
          </p>
          <span className='theservices'>Before & After</span>
          <p className='servicesdes'>
          Explore the remarkable transformations, and envision the endless possibilities as we create healthier, happier smiles at Spring Farm Dental
          </p>
        </div>
      </div>
      <Slider {...settings}>
        <div data-aos='fade-up'>
          <div className='slider'>
            <ImageSlider
              image1={img1}
              image2={img2}
              sliderColor='#644222'
              handleColor='#FAEBD7'
              handleBackgroundColor='#644222'
              leftLabelText='After'
              rightLabelText='Before'
              sliderWidth={4}
              onSlide={() => {
                console.log('sliding');
              }}
            />
          </div>
        </div>
        {/* Add data-aos attribute to other slider elements */}
        <div data-aos='fade-up'>
          <div className='slider'>
            <ImageSlider
              image1={img3}
              image2={img4}
              sliderColor='#644222'
              handleColor='#FAEBD7'
              handleBackgroundColor='#644222'
              leftLabelText='After'
              rightLabelText='Before'
              sliderWidth={4}
              onSlide={() => {
                console.log('sliding');
              }}
            />
          </div>
        </div>
        <div data-aos='fade-up'>
          <div className='slider'>
            <ImageSlider
              image1={img5}
              image2={img6}
              sliderColor='#644222'
              handleColor='#FAEBD7'
              handleBackgroundColor='#644222'
              leftLabelText='After'
              rightLabelText='Before'
              sliderWidth={4}
              onSlide={() => {
                console.log('sliding');
              }}
            />
          </div>
        </div>
        <div data-aos='fade-up'>
          <div className='slider'>
            <ImageSlider
              image1={img7}
              image2={img8}
              sliderColor='#644222'
              handleColor='#FAEBD7'
              handleBackgroundColor='#644222'
              leftLabelText='After'
              rightLabelText='Before'
              sliderWidth={4}
              onSlide={() => {
                console.log('sliding');
              }}
            />
          </div>
        </div>
        <div data-aos='fade-up'>
          <div className='slider'>
            <ImageSlider
              image1={img9}
              image2={img10}
              sliderColor='#644222'
              handleColor='#FAEBD7'
              handleBackgroundColor='#644222'
              leftLabelText='After'
              rightLabelText='Before'
              sliderWidth={4}
              onSlide={() => {
                console.log('sliding');
              }}
            />
          </div>
        </div>
        <div data-aos='fade-up'>
          <div className='slider'>
            <ImageSlider
              image1={img11}
              image2={img12}
              sliderColor='#644222'
              handleColor='#FAEBD7'
              handleBackgroundColor='#644222'
              leftLabelText='After'
              rightLabelText='Before'
              sliderWidth={4}
              onSlide={() => {
                console.log('sliding');
              }}
            />
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default After;
