import React, { useEffect } from 'react';
import '../css/Book.css';
import AOS from 'aos';

const Book = () => {
    useEffect(() => {
        AOS.init({ duration: 2000, 
            once: false, // Animations will only occur once
            mirror: true, // Animation will not be mirrored when scrolling down
        }); // Initialize AOS with a default duration of 1000ms
      }, []);
      
    return (
        <div className='bookbg'>
            <div className='bor'></div>
            <div className='bookwrap'>
                <div className='bookcontent' data-aos='fade-up'>
                    <h1 data-aos='fade-up' >Book your consultation with us today</h1>
                    <p data-aos='zoom-out' >Your one-stop destination for all your dental needs! Our comprehensive range of services covers everything from general dentistry to advanced procedures like dental implants. Rest assured, our skilled team is equipped to handle it all with precision and care. Experience excellence in oral care today!
                        <br /><br />
                        Discover Spring Farm Dental - where local charm meets cutting-edge care! Our friendly team awaits you in our brand new clinic. Experience personalized service and leave with a beaming smile! Schedule your visit now!</p>
                    <div className='aboutbutton'>
                    <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'> <button className='aboutbookbutton' data-aos='fade-up'>Book Now</button></a>
 <br /><br /><br /><br />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Book;
