import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../css/Whycard.css'

const WhyCard = ({ imgSrc, title, points }) => {
  useEffect(() => {
    AOS.init({ duration: 1000,
        once: false, // Animations will only occur once
        mirror: true, // Animation will not be mirrored when scrolling down
    }); // Initialize AOS with a default duration of 1000ms
  }, []);

  return (
    <div className='whycard' data-aos='fade-up' >
      <img className='whygif' src={imgSrc} alt='Why Card' data-aos='fade-up' ></img>
      <h1 className='whyh' data-aos='fade-up' >{title}
      <p className='whyp' data-aos='fade-up' >{points}</p></h1>
    </div>
  );
};

export default WhyCard;
