import React, { useEffect } from 'react';
import '../css/Why.css'
import img1 from '../gifs/healthcare.gif'
import img2 from '../gifs/braces.gif'
import img3 from '../gifs/medicine.gif'
import img4 from '../gifs/heartbeat.gif'
import img5 from '../gifs/dentist-chair.gif'
import img6 from '../gifs/dental-care.gif'
import WhyCard from './Whycard'
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../css/Whycard.css'

const Why = () => {
    useEffect(() => {
        AOS.init({ duration: 1000,
            once: false, // Animations will only occur once
            mirror: true, // Animation will not be mirrored when scrolling down
        }); // Initialize AOS with a default duration of 1000ms
      }, []);

    return (
        <div className='whybg' data-aos='fade-up' >
            <div className='whyheading'  data-aos='fade-up'>
            <p>WHY US</p>
            <h1>Why You Should Use Our Medical Services</h1>
            </div>
            <div className='whygrid'  data-aos='fade-up'>
                <div>
                    <WhyCard imgSrc={img1} title="We Care And Respect" points={"We don’t judge, take time to listen, care and respect your fears and concerns, and we are considerate with other team members."
                    } />
                </div>
                <div>
                    <WhyCard imgSrc={img2} title="We Deliver What We Say" points={"We deliver what we promise to do, we are responsive and follow through, and do whatever it takes to exceed expectations."
                    } />
                </div>
                <div>
                    <WhyCard imgSrc={img3} title="We Do Everything With Passion" points={"We love our clients and what we do, ensuring an outstanding dental experience with genuine care and dedication."
                    } />
                </div>
                <div>
                    <WhyCard imgSrc={img5} title="We Pay Attention To Detail" points={"It is in the little details that make the difference; we do everything thoroughly, focusing on getting it right the first time."
                    } />
                </div>
                <div>
                    <WhyCard imgSrc={img4} title="We Serve With Smile" points={"We care about your health with all our heart and sincerity, so you can live happily and confidently, knowing you're in good hands.    "
                    } />
                </div>
                <div>
                    <WhyCard imgSrc={img6} title="We Lead The Way" points={"We challenge mediocrity, have the courage to think differently, and inspire and empower people to achieve the smile they deserve."
                    } />
                </div>

            </div>
        </div>
    )
}

export default Why