import React, { useEffect } from 'react';
import '../css/Who.css'
import img1 from '../images/abtimg6.webp'
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../css/Whycard.css'

const Who = () => {
    useEffect(() => {
        AOS.init({ duration: 2000,
            once: false, // Animations will only occur once
            mirror: true, // Animation will not be mirrored when scrolling down
        }); // Initialize AOS with a default duration of 1000ms
      }, []);
    return (
        <>
            <div className='whobg' data-aos='fade-up' >
                <div className='whowrap' data-aos='fade-up' >
                    <div className='whogrid' data-aos='fade-up' >
                        <div className='whoh' data-aos='fade-up' >
                            <h1>Who Are We?</h1>
                            <br></br>
                        </div>
                        <div className='whop' data-aos='fade-up' >
                            <p>Your local choice for confident smiles in South-West Sydney. Renowned for cosmetic and implant dentistry. By the community, for the community. Highly qualified, compassionate, and dedicated.</p>
                        </div>
                    </div>
                </div>
                <div className='whobgg'  data-aos='fade-up'>
                    <div className='whobg1'  data-aos='fade-up'>

                        <div className='whogrid'  data-aos='fade-up'>
                            <div className='whoimg'  data-aos='fade-up'>
                                <img className='whosimg' src={img1}  data-aos='fade-up'></img>
                            </div>
                            <div className='whocontent'  data-aos='fade-up'>
                                <h1 className='whocontenth'  data-aos='fade-up'>
                                    OUR STORY <br />
                                </h1>
                                <p className='whocontentp'  data-aos='fade-up'>
                                    Spring-Farm Dental was born with the birth of the suburb.
                                    We have mastered the skills to provide tailoring, designing and crafting harmonious smiles, and draw on their many decades of experience to redefine the concept of smile design.
                                    <br /><br />
                                    Dr Goyal was 9 years old when he saw his 6 year old brother screaming and crying while having dental work done. Dr Goyal still remembers the day like it was yesterday and believes that that incident is what shook him to his core.
                                    <br /><br />
                                    The evolution of SpringFarm Dental was inspired by Dr. Goyal’s personal story, to provide painless experience and help people overcome their fears to achieve the dental health and smile they deserve.
                                    <br /><br />
                                    With years of dedicated learning and innovation, SpringFarm Dental has created a system which delivers exceptional results, every time.
                                    <br />
                                </p>
                            <div className='aboutbutton'>
                              <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'> 
                                  <button className='aboutbookbutton' data-aos='fade-up'>
                                    Contact Us
                                </button>
                                </a>
                            </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Who