import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import OffersCard from '../components/Offerscard.js';
import '../css/Offers.css';

const Offers = () => {
  useEffect(() => {
    AOS.init({ duration: 1000,
      once: true, // Animations will only occur once
      mirror: false, // Animation will not be mirrored when scrolling down
    }); // Initialize AOS with a default duration of 1000ms
  }, []);

  return (
    <div className='offerr'>
      <div className='offerswrap'>
        <div className='offersgrid'>
          <div className='offerscontent' data-aos='fade-up'>
            <span className='bestprices'>Best Prices</span>
            <h1>Extra Offers</h1>
            <p>At Spring Farm Dental Clinic, your oral health is our top priority. We are dedicated to providing comprehensive dental care that meets your unique needs. Our experienced team of dentists and staff are here to ensure you enjoy a healthy, beautiful smile for life.</p>
          </div>

          <div className='offercards'>
            <OffersCard
              heading="GAP FREE Check-Up"
              subheading="Individual Offer"
              price="FREE"
              description={[
                'New patients, kickstart your oral health journey with a complimentary dental check-up.Enjoy a clean slate with a free GAP check-up for new patients.*'
              ]}
              data-aos='fade-up'
            />
            <OffersCard
              heading="Dental Clean Up"
              subheading="Individual Offer"
              price="FREE"
              description={[
                'New patients, begin your dental care with a no-cost teeth cleaning. Get a head start on oral health with a free cleaning for all first-time visitors.*'
              ]}
              data-aos='fade-up'
            />
            <OffersCard
              heading="Consultation for Implants"
              subheading="Individual Offer"
              price="FREE"
              description={[
                'Curious about dental implants? Discover the possibilities with a free consultation, where you can learn about this tooth-saving solution at no cost.*',
                
              ]}
              data-aos='fade-up'
            />
            <OffersCard
              heading="Consultation for Full Mouth Implants"
              subheading="Individual Offer"
              price="FREE"
              description={[
                'Explore the world of full mouth implants with a complimentary consultation. Find out how this comprehensive solution can transform your smile, free of charge.*'
              ]}
              data-aos='fade-up'
            />
            <OffersCard
              heading="Consultation for Dental Veneers"
              subheading="Individual Offer"
              price="FREE"
              description={[
                'Get a glimpse of your dream smile with a free veneer consultation. Learn how dental veneers can enhance your teeth"s appearance, all at no cost.*'
              ]}
              data-aos='fade-up'
            />
            <OffersCard
              heading="Consultation for Wisdom Teeth Removal"
              subheading="Individual Offer"
              price="FREE"
              description={[
                'Wisdom teeth causing concern? Seek expert advice without the fees. Our free consultation will help you understand your wisdom teeth removal options.*'
              ]}
              data-aos='fade-up'
            />
     
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offers;
