import React from 'react'
import '../css/Dentalservice.css'
import Serviceop from './Serviceop'
import Spforms from './Spform'
import Why from './Why'

const Dentalservice = () => {
  return (
    <div className='dentalwrap'>
      <div className='seropcon'>

        <h1>Our Comprehensive Dental Services</h1>
        <p>Welcome to Spring Farm Dental, your trusted partner for all your dental needs. Our experienced team of dentists is committed to providing top-quality dental care to help you achieve and maintain a healthy, beautiful smile. We offer a wide range of dental services to cater to patients of all ages. Explore our comprehensive services below:</p>
      </div>
      <div className='dentalgrid'>
        <div>

          <Serviceop />
        </div>
        <div>
          <Spforms />
        </div>
      </div>
      <Why />
    </div>
  )
}

export default Dentalservice