import React from 'react'
import { Link } from 'react-router-dom'

const Locationop = () => {
  return (
    <>
        <div className='dentalwrap'>

     <div className='seropcon'>

<h1>Our Locations</h1>
<p>Welcome to Spring Farm Dental, your trusted partner for all your dental needs. Our experienced team of dentists is committed to providing top-quality dental care to help you achieve and maintain a healthy, beautiful smile. We offer a wide range of dental services to cater to patients of all ages. Explore our Locations below:</p>
</div>
      <div>
        <nav className='serviceop'>
          <div className='spotherservices'>
            <h1>Locations We Serve</h1>
            <br />
            <p>__________</p>
            <br />
          </div>
          <div className='locationgrid'>

          
          <ul className={`drop-down-no`}>
            <li>
              <Link to='/Camden'>
                <a className="nav-button">
                  Camden →
                </a>
              </Link>
            </li>
          </ul>

          <ul className={`drop-down-no`}>
            <li>
              <Link to='/Narellan'>
                <a className="nav-button">
                  Narellan →
                </a>
              </Link>
            </li>
          </ul>

          <ul className={`drop-down-no`}>
            <li>
              <Link to='/Elderslie'>
                <a className="nav-button">
                  Elderslie →
                </a>
              </Link>
            </li>
          </ul>

          <ul className={`drop-down-no`}>
            <li>
              <Link to='/MountAnnan'>
                <a className="nav-button">
                  Mount Annan →
                </a>
              </Link>
            </li>
          </ul>

          <ul className={`drop-down-no`}>
            <li>
              <Link to='/CamdenPark'>
                <a className="nav-button">
                  Camden Park →
                </a>
              </Link>
            </li>
          </ul>

          <ul className={`drop-down-no`}>
            <li>
              <Link to='/OranPark'>
                <a className="nav-button">
                  Oran Park →
                </a>
              </Link>
            </li>
          </ul>

          <ul className={`drop-down-no`}>
            <li>
              <Link to='/GregoryHills'>
                <a className="nav-button">
                  Gregory Hills →
                </a>
              </Link>
            </li>
          </ul>

          <ul className={`drop-down-no`}>
            <li>
              <Link to='/SmeatonGrange'>
                <a className="nav-button">
                  Smeaton Grange →
                </a>
              </Link>
            </li>
          </ul>

          <ul className={`drop-down-no`}>
            <li>
              <Link to='/Cobbitty'>
                <a className="nav-button">
                  Cobbitty →
                </a>
              </Link>
            </li>
          </ul>

          <ul className={`drop-down-no`}>
            <li>
              <Link to='/TheOaks'>
                <a className="nav-button">
                  The Oaks →
                </a>
              </Link>
            </li>
          </ul>

          <ul className={`drop-down-no`}>
            <li>
              <Link to='/Appin'>
                <a className="nav-button">
                  Appin →
                </a>
              </Link>
            </li>
          </ul>

          <ul className={`drop-down-no`}>
            <li>
              <Link to='/Menangle'>
                <a className="nav-button">
                  Menangle →
                </a>
              </Link>
            </li>
          </ul>


          
          </div>
        </nav>
      </div>
      </div>
    </>
  )
}

export default Locationop