import React, { useEffect } from 'react';
import '../css/System.css'
import img1 from '../images/Frame13.png'
import img2 from '../images/Frame14.png'
import img3 from '../images/Frame12.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../css/Whycard.css'

const System = () => {

    useEffect(() => {
        AOS.init({
            duration: 2000,
            once: false, // Animations will only occur once
            mirror: true, // Animation will not be mirrored when scrolling down
        }); // Initialize AOS with a default duration of 1000ms
    }, []);

    return (
        <div className='syscontainer' data-aos='fade-up' >
            <div className='sysbg' data-aos='fade-up' >
                <div className='syswrap' data-aos='fade-up'  >
                    <div className='sysdiv' data-aos='fade-up'  >
                        <h1>
                            Our System
                        </h1>
                        <p>
                            We are creating and transforming smiles and confidence day by day by following 3 simple steps.
                        </p>
                    </div>
                    <div className='sysgrid' data-aos='fade-up' >
                        <div className='syscard' data-aos='fade-up' >
                            <div className='syscardno' data-aos='fade-up' >
                                1
                            </div>
                            <img src={img1}></img>
                            <h1 className='syscardh'  >
                                Knowing our patients
                            </h1>
                            <h2 className='syscardsub'  >
                            During your first appointment, we dedicate the entire time to getting to know you better – your unique story, your experiences, and your dental journey. Our primary goal is to be a part of your story and understand your needs fully. By truly comprehending your individual needs, we can provide the best care and support throughout your dental experience with us.
                            </h2>
                        </div>
                        <div className='syscard' data-aos='fade-up' >
                            <div className='syscardno' data-aos='fade-up' >
                                2
                            </div>
                            <img src={img2}></img>
                            <h1 className='syscardh'  >
                                Knowing your desires / needs
                            </h1>
                            <h2 className='syscardsub'  >
                                We recognize that each patient has different desires and needs when it comes to their smiles. By actively listening to your desires and needs, we can tailor our treatments to meet your expectations and deliver results that leave you with the smile you've always dreamed of.
                            </h2>
                        </div>
                        <div className='syscard' data-aos='fade-up' >
                            <div className='syscardno' data-aos='fade-up' >
                                3
                            </div>
                            <img src={img3}></img>
                            <h1 className='syscardh'  >
                                Working endlessly to deliver
                            </h1>
                            <h2 className='syscardsub'  >
                                Our passion is creating and transforming smiles. With skill and artistry, our dedicated team brings your dream smile to life. From cosmetic enhancements to restorative procedures, we strive for excellence. Your confidence is revitalized, and you leave with a radiant smile, day after day.
                            </h2>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default System