import React from 'react'
import Offers from './Offers'
import PaymentPlan from './Paymentplan'

const Pricing = () => {
  return (
    <div>
         <Offers />
      <PaymentPlan />
    </div>
  )
}

export default Pricing