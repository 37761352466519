import React, { useEffect } from 'react';
import '../css/Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaMailBulk } from 'react-icons/fa';

function Footer() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
      mirror: true,
    });
  }, []);



  return (
    <div id='footeer' className='footer-container' data-aos="fade-up">
      <section className='social-media' data-aos="fade-up">
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <a to='/' className='social-logo'>
              Spring Farm Dental
            </a>
          </div>
          <small className='website-rights'>SFDC © 2023-2024</small>
          <div className='social-icons'>
            <a className='social-icon-link facebook' href='https://www.facebook.com' target='_blank' rel='noopener noreferrer' aria-label='Facebook'>
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a className='social-icon-link instagram' href='https://www.instagram.com/dr.ankitgoyal_/' target='_blank' rel='noopener noreferrer' aria-label='Instagram'>
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a className='social-icon-link twitter' href='https://wa.me/+61449968018?text=Hi%20Doctor' target='_blank' rel='noopener noreferrer' aria-label='Twitter'>
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
            <a className='social-icon-link linkedin' href='https://www.linkedin.com' target='_blank' rel='noopener noreferrer' aria-label='LinkedIn'>
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a className='social-icon-link linkedin' href="mailto:springfarmdental@gmail.com" target="_blank" rel="noopener noreferrer">
              <FaMailBulk className="socials" />
            </a>
          </div>
          <button className='dropdown-toggle'>
            <a href='/Attributions'>
              Attributions
            </a>
          </button>
        </div>
      </section>
      <iframe
        className='googlemaps'
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3304.9909062089187!2d150.7264162754612!3d-34.06974737315033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12f05db4252767%3A0xc8b58064382236d!2sSpring%20Farm%20Dental!5e0!3m2!1sen!2sin!4v1689152617995!5m2!1sen!2sin"
        width="100%"
        height="400"
        style={{ border: '0' }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        data-aos="fade-up"
      ></iframe>
    </div>
  );
}

export default Footer;
