import img1 from '../images/ortho1.webp'
import img2 from '../images/general1.webp'
import img3 from '../images/genser1.webp'
import img4 from '../images/genser2.webp'
import img5 from '../images/genser3.webp'
import img6 from '../images/genser4.webp'
import img7 from '../images/genser5.webp'
import img8 from '../images/genser6.webp'
import '../css/Generaldentistry.css'
import React, { useState } from 'react';
import Serviceop from './Serviceop'
import Spforms from './Spform'

const Generaldentistry = () => {

    return (
        <>
  <div className='spbanner'>
    <img className='spimg' src={img1} alt="General Dentistry Image" />
  </div>
  <div className='spbbwrap'>
    <div className='spbbottom' data-aos='fade-up'>
      <span>Home {'>'} Services {'>'} General Dentistry </span>
      <h1>GENERAL DENTISTRY: Personalized Care</h1>
      <p>Whether you require a routine dental check-up and cleaning, need a filling or extraction, or are interested in exploring cosmetic dentistry options, our dental team at Spring Farm Dental is here to assist you. Our General Dentistry clinic in Spring Farm in Sydney covers a wide range of areas, including Children's Dentistry, Trauma Care, and all aspects related to your oral hygiene, preventive measures, and restorative dentistry.</p>
      <p>We not only identify and address minor issues before they escalate but also provide valuable advice on maintaining your dental health between appointments. Our commitment to dental excellence is matched by our dedication to personalized care. We take the time to understand your unique needs and design a customized treatment plan to help you achieve your best smile.</p>
    </div>
  </div>

  <div className='veneersgrid'>
    <div className='veneerscontent'>
      <h1>Compassionate General and Cosmetic Dentists</h1>
      <hr />
      <p>At Spring Farm Dental, we go beyond standard dental care. We are dedicated to providing our patients with an experience that shows genuine care. When you step into our clinic, you'll instantly feel at home in our welcoming and spacious environment. We understand that visiting the dentist can be anxiety-inducing for some, so our devoted family dentists in Spring Farm strive to ensure that every visit is comfortable and enjoyable. We believe in safeguarding every smile, which is why we cultivate enduring relationships with all our patients. In addition to our regular office hours, our Spring Farm clinic is accessible after hours and for emergencies.</p>
      <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'> <button className='aboutbookbutton' data-aos='fade-up'>Book Now</button></a>

    </div>

    <div className='veneersimg'>
      <img src={img1} alt="General Dentistry Image" />
    </div>
  </div>

  <div className='generaldenser'>
    <p>At Spring Farm Dental, we offer a comprehensive range of general dentistry services to cater to your oral health needs.</p>
    <h1>Our General Dentistry Services</h1>
  </div>

  <div className='gendenser' id='genden'>
    {/* General Dentistry Service Cards */}
    <div className="card">
      <div className="card-image" style={{ backgroundImage: `url(${img3})` }}></div>
      <h2 className="card-heading">Check Up And Clean</h2>
      <p className="card-description">A dental check-up and clean at Spring Farm Dental is a quick and easy way to maintain oral health. Our experienced dentists examine your mouth for issues like cavities and gum disease, while also addressing your questions. We recommend this every six months.</p>
    </div>
    <div className="card">
      <div className="card-image" style={{ backgroundImage: `url(${img4})` }}></div>
      <h2 className="card-heading">Fillings</h2>
      <p className="card-description">If you're dealing with a cavity, need to restore a chipped tooth, or have a lost filling, Spring Farm Dental can assist you. Fillings are a common dental procedure and should be promptly addressed to prevent further tooth damage. The process of filling a chipped tooth is quick and non-invasive, ensuring a pain-free experience.</p>
    </div>
    <div className="card">
      <div className="card-image" style={{ backgroundImage: `url(${img5})` }}></div>
      <h2 className="card-heading">Root Canal Treatment</h2>
      <p className="card-description">If you've had a severe toothache, it might indicate the need for root canal treatment. Our Spring Farm clinic can perform this procedure in about two to three visits, assessing and treating infected teeth. The cost varies based on factors like the tooth, infection severity, appointments, and treatment type. Contact us for a personalized treatment plan and pricing.</p>
    </div>
    <div className="card">
      <div className="card-image" style={{ backgroundImage: `url(${img6})` }}></div>
      <h2 className="card-heading">Crowns and Bridges</h2>
      <p className="card-description">If you have a broken, fractured, or cracked tooth, quick action is crucial. Our General Dentists at Spring Farm Dental can restore both the function and appearance of your tooth with a dental crown. In cases of missing teeth, a bridge may be necessary. Consult with our team to determine if you're a suitable candidate for this fixed solution.</p>
    </div>
    <div className="card">
      <div className="card-image" style={{ backgroundImage: `url(${img7})` }}></div>
      <h2 className="card-heading">Wisdom Teeth Removal</h2>
      <p className="card-description">If it's time to consider the removal of your four permanent adult teeth at the back corners of your mouth, this is commonly known as wisdom teeth removal. This procedure is typically performed in our Spring Farm clinic. The average cost for wisdom teeth removal in Spring Farm varies depending on its complexity. While Medicare doesn't cover wisdom teeth removal, you may have partial coverage through your private health insurance.</p>
    </div>
    <div className="card">
      <div className="card-image" style={{ backgroundImage: `url(${img8})` }}></div>
      <h2 className="card-heading">Children’s Dentistry</h2>
      <p className="card-description">At Spring Farm Dental, we offer comprehensive oral health care for children from infancy through adolescence. We recommend scheduling your child's first dentist visit as early as 12 months of age. To ease any anxiety, encourage them to bring their favorite stuffed animal along for comfort. The earlier you introduce them to our children's dentist, the more positive their experience will be. Additionally, we provide bulk billing for CDBS-eligible children.</p>
    </div>
  </div>

  <div className='genbookgrid'>
    <div className='genbookimg'>
      <img src={img2} alt="Contact Us Image" />
    </div>
    <div className='genbookcon'>
      <h1>Contact Us Today</h1>
      <p>At Spring Farm Dental, dental excellence isn't just a goal; it's a way of life for us. We are deeply passionate about our work, and our devoted family dentists in Spring Farm are committed to providing the best possible care for you and your family. We invite you to discover the positive impact that personalized dental care can have on your life.</p>
      <p>Our doors are always open to welcome new patients, and for your convenience, our dentists often work after hours. We eagerly await the opportunity to meet you at our Spring Farm clinic in Sydney.</p>
      <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'> <button className='aboutbookbutton' data-aos='fade-up'>Book Now</button></a>

    </div>
  </div>

  <div className='dentalgrid'>
                <div>
                    <Serviceop />
                </div>
                <div>
                    <Spforms />
                </div>
            </div>


            


</>




    )
}

export default Generaldentistry