import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../css/Services.css';
import img1 from '../images/logocard.png';
import img8 from '../svg/teeth-bacteria.svg';
import img3 from '../svg/braces-teeth.svg';
import img4 from '../svg/teeth-whitening.svg';
import img5 from '../svg/dentist-tool.svg';
import img6 from '../svg/teeth-implant.svg';
import img7 from '../svg/emergency-siren.svg';
import ContentCard from './Contentcard';
import img2 from '../images/dentalhover2.webp';

const Services = () => {
  useEffect(() => {
    AOS.init({ duration: 3000,
        once: true, // Animations will only occur once
        mirror: true, // Animation will not be mirrored when scrolling down
    }); // Initialize AOS with a default duration of 1000ms
  }, []);

  return (
    <div className='serviceswrap'>
      <img className='serviceshoverimg' data-aos='fade-up' src={img2} />
      <div className='servicescontent' data-aos='fade-up'>
        <p className='explore' style={{ color: '#644222', fontWeight: '1000',fontSize:'2rem' }}>
          Explore
        </p>
        <span className='theservices'>The Services</span>
        <p className='servicesdes'>
        Our skilled team is dedicated to ensuring your comfort and satisfaction throughout your dental journey. At Spring Farm Dental, we believe in the power of a healthy, beautiful smile, and we're excited to help you achieve it.
        </p>
      </div>
      <div className='servicesdiv'>
        <div data-aos='fade-up'>
          <ContentCard
            imgSrc1={img1}
            imgSrc={img8}
            serviceName="Check Up And Clean"
            description="Ensure your oral health with our comprehensive check-up and cleaning services."
            link="/Checkupandclean"
          />
        </div>
        <div data-aos='fade-left'>
          <ContentCard
            imgSrc1={img1}
            imgSrc={img3}
            serviceName="Veneers"
            description="Transform your smile with porcelain or composite veneers for a flawless look."
            link="/Veneers"
          />
        </div>
        <div data-aos='fade-right'>
          <ContentCard
            imgSrc1={img1}
            imgSrc={img4}
            serviceName="Teeth Whitening"
            description="Brighten your smile with our effective teeth whitening treatments."
            link="/Teethwhite"
          />
        </div>
        <div data-aos='fade-down'>
          <ContentCard
            imgSrc1={img1}
            imgSrc={img5}
            serviceName="Orthodontics/Invisalign"
            description="Achieve a perfectly aligned smile with our orthodontic solutions, including Invisalign."
            link="/Orthodontics-Invisalign"
          />
        </div>
        <div data-aos='fade-up'>
          <ContentCard
            imgSrc1={img1}
            imgSrc={img6}
            serviceName="Dental Implants"
            description="Restore missing teeth and regain your confidence with our dental implant expertise."
            link="/Dental-Implants"
          />
        </div>
        <div data-aos='fade-left'>
          <ContentCard
            imgSrc1={img1}
            imgSrc={img7}
            serviceName="Emergency Dentistry"
            description="We're here when you need us most, providing prompt and reliable emergency dental care."
            link="/Emergency-Dentistry"
          />
        </div>
      </div>
    </div>
  );
};

export default Services;
