import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import './css/Global.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from './components/Home';
import Navbar from './components/Navbar';
import Aboutpage from './components/Aboutpage';
import Servicepage from './components/Servicepage';
import Contactpage from './components/Contactpage';
import Dentalservice from './components/Dentalservice';
import Checkupandclean from './components/Checkupandclean';
import Orthoandbraces from './components/Orthoandbraces';
import Energencydentistry from './components/Energencydentistry';
import Veneers from './components/Veneers';
import Teethwhite from './components/Teethwhite';
import Generaldentistry from './components/Generaldentistry';
import Pricing from './components/Pricing';
import Attributions from './components/Attributions';
import ScrollToTop from './components/ScrollToTop';
import Locationop from './components/Locations';

import Camdenimplants from './components/location/Camdem/Camdenimplants';
import Camdenveneers from './components/location/Camdem/Camdenveneers';
import Camden from './components/location/Camdem/Camden';

import CamdenPark from './components/location/CamdenPark/CamdenPark';
import CamdenParkimplants from './components/location/CamdenPark/CamdenParkimplants';
import CamdenParkveneers from './components/location/CamdenPark/CamdenParkveneers';

import Appinimplants from './components/location/Appin/Appinimplants';
import Appinveneers from './components/location/Appin/Appinveneers';
import Appin from './components/location/Appin/Appin';
import Appinemergency from './components/location/Appin/Appinemergency';

import MountAnnanimplants from './components/location/MountAnnan/MountAnnanimplants';
import MountAnnanveneers from './components/location/MountAnnan/MountAnnanveneers';
import MountAnnan from './components/location/MountAnnan/MountAnnan';

import GregoryHillsimplants from './components/location/GregoryHills/GregoryHillsimplants';
import GregoryHillsveneers from './components/location/GregoryHills/GregoryHillsveneers';
import GregoryHills from './components/location/GregoryHills/GregoryHills';

import Narellanimplants from './components/location/Narellan/Narellanimplants';
import Narellanveneers from './components/location/Narellan/Narellanveneers';
import Narellan from './components/location/Narellan/Narellan';

import SmeatonGrangeimplants from './components/location/SmeatonGrange/SmeatonGrangeimplants';
import SmeatonGrangeveneers from './components/location/SmeatonGrange/SmeatonGrangeveneers';
import SmeatonGrange from './components/location/SmeatonGrange/SmeatonGrange';

import TheOaksimplants from './components/location/TheOaks/TheOaksimplants';
import TheOaksveneers from './components/location/TheOaks/TheOaksveneers';
import TheOaks from './components/location/TheOaks/TheOaks';

import Elderslieimplants from './components/location/Elderslie/Elderslieimplants';
import Elderslieveneers from './components/location/Elderslie/Elderslieveneers';
import Elderslie from './components/location/Elderslie/Elderslie';

import OranParkimplants from './components/location/OranPark/OranParkimplants';
import OranParkveneers from './components/location/OranPark/OranParkveneers';
import OranPark from './components/location/OranPark/OranPark';

import Cobbittyimplants from './components/location/Cobbitty/Cobbittyimplants';
import Cobbittyveneers from './components/location/Cobbitty/Cobbittyveneers';
import Cobbitty from './components/location/Cobbitty/Cobbitty';

import Menangleimplants from './components/location/Menangle/Menangleimplants';
import Menangleveneers from './components/location/Menangle/Menangleveneers';
import Menangle from './components/location/Menangle/Menangle';


function App() {
  return (
    <>
      <Header>
      </Header>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Switch>
          <Route path='/' component={Home} exact />
          <Route path="/Dental-Implants" component={Servicepage} />
          <Route path="/Aboutpage" component={Aboutpage} />
          <Route path="/Contactpage" component={Contactpage} />
          <Route path="/Dentalservice" component={Dentalservice} />
          <Route path="/Checkupandclean" component={Checkupandclean} />
          <Route path="/Orthodontics-Invisalign" component={Orthoandbraces} />
          <Route path="/Emergency-Dentistry" component={Energencydentistry} />
          <Route path="/Veneers" component={Veneers} />
          <Route path="/Teethwhite" component={Teethwhite} />
          <Route path="/Generaldentistry" component={Generaldentistry} />
          <Route path="/Pricing" component={Pricing} />
          <Route path="/Attributions" component={Attributions} />
          <Route path="/Locations" component={Locationop} />

          <Route path="/Camdenimplants" component={Camdenimplants} />
          <Route path="/Camdenveneers" component={Camdenveneers} />
          <Route path="/Camden" component={Camden} />


          <Route path="/CamdenPark" component={CamdenPark} />
          <Route path="/CamdenParkveneers" component={CamdenParkveneers} />
          <Route path="/CamdenParkimplants" component={CamdenParkimplants} />


          <Route path="/GregoryHillsimplants" component={GregoryHillsimplants} />
          <Route path="/GregoryHillsveneers" component={GregoryHillsveneers} />
          <Route path="/GregoryHills" component={GregoryHills} />


          <Route path="/Narellanimplants" component={Narellanimplants} />
          <Route path="/Narellanveneers" component={Narellanveneers} />
          <Route path="/Narellan" component={Narellan} />

          <Route path="/SmeatonGrangeimplants" component={SmeatonGrangeimplants} />
          <Route path="/SmeatonGrangeveneers" component={SmeatonGrangeveneers} />
          <Route path="/SmeatonGrange" component={SmeatonGrange} />



          <Route path="/MountAnnanimplants" component={MountAnnanimplants} />
          <Route path="/MountAnnanveneers" component={MountAnnanveneers} />
          <Route path="/MountAnnan" component={MountAnnan} />

          <Route path="/TheOaksimplants" component={TheOaksimplants} />
          <Route path="/TheOaksveneers" component={TheOaksveneers} />
          <Route path="/TheOaks" component={TheOaks} />

          <Route path="/Elderslieimplants" component={Elderslieimplants} />
          <Route path="/Elderslieveneers" component={Elderslieveneers} />
          <Route path="/Elderslie" component={Elderslie} />

          <Route path="/OranParkimplants" component={OranParkimplants} />
          <Route path="/OranParkveneers" component={OranParkveneers} />
          <Route path="/OranPark" component={OranPark} />

          <Route path="/Cobbittyimplants" component={Cobbittyimplants} />
          <Route path="/Cobbittyveneers" component={Cobbittyveneers} />
          <Route path="/Cobbitty" component={Cobbitty} />

          <Route path="/Menangleimplants" component={Menangleimplants} />
          <Route path="/Menangleveneers" component={Menangleveneers} />
          <Route path="/Menangle" component={Menangle} />

          <Route path="/Appinimplants" component={Appinimplants} />
<Route path="/Appinveneers" component={Appinveneers} />
<Route path="/Appin" component={Appin} />
<Route path="/Appinemergency" component={Appinemergency} />








        </Switch>
      </Router>
      <Footer></Footer>
    </>
  );
}

export default App;
