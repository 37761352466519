import React from 'react'
import img1 from '../images/indioffer1.webp'
import '../css/Indioffer.css'


const Indioffer = () => {
    return (
        <>
        <div className='indiofferrwrap'>
            <div className='indiofferwrap'>
                <div className='indiofferwrap1'>

                    <h1>SPECIAL OFFER</h1>
                    <div className='indigrid'>
                        <div className='indiofferimg'>
                            <img src={img1} ></img>
                        </div>
                        <div className='indioffercon'>
                            <h2>Book Today
                                <br/>
                            &
                                <br/>
                            Claim Your
                                <br/>
                            Check Up And Clean
                                <br/>
                            @
                                <br/>
                            FREE
                                <br/>
                            (For all Health Fund Holders)</h2>

                            <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'> <button className='aboutbookbutton' data-aos='fade-up'>Book Now</button></a>
                            <h4>
                            We Accept all Types of Health Funds
                            </h4>
                            <br/>
                        
                           
                        </div>
                    

                    </div>

                </div>
                </div>
            </div>
        </>
    )
}

export default Indioffer