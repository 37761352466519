import React, { useEffect } from 'react'
import AOS from 'aos';
import img1 from '../images/sp.webp'
import img2 from '../images/sp2.webp'
import img3 from '../images/sp3.webp'
import '../css/Servicepage.css'
import Servicecomponent from './Servicecomponent'
import Spcarousel from './Spcarousel'
import Spforms from './Spform'
import Spoffers from './Spoffers'
import Book from './Book'
import Serviceop from './Serviceop';

const Servicepage = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true, // Animations will only occur once
            mirror: true, // Animation will not be mirrored when scrolling down
        }); // Initialize AOS with a default duration of 1000ms
    }, []);
    return (
        <>
            <div className='spbanner'>
                <img className='spimg' src={img1}></img>
            </div>
            <div className='spbbwrap'>
                <div className='spbbottom' data-aos='fade-up'>
                    <span>Home {'>'} Services {'>'} Dental Implants </span>
                    <h1>DENTAL IMPLANTS</h1>
                    <p>Our team of experienced, Sydney based, dental implant specialists can help you decide whether dental implants are the best solution for your case – providing context as well as alternatives where possible. If you’re still not sure about whether you need dental implants you can book a FREE* consultation (*Or Health Fund only, no gap consultation) at either of our Sydney dental clinics: Bondi Junction or North Sydney  to discuss your case with someone from our experienced dentist team.</p>
                </div>
            </div>
            <div className='spwrap' data-aos='fade-up'>

                <div className='spg1' data-aos='fade-up'>
                    <img src={img2} data-aos='fade-right'></img>
                    <div className='spg1con' data-aos='fade-left'>
                        <h1>What is a Dental Implant?</h1>
                        <p>A dental implant is a procedure that involves a metal fixture
                            (usually a screw) being fixed into the jawbone, which then
                            acts as an anchor for a new false tooth.
                            It seems straightforward, but this form of cosmetic dental
                            surgery is quite an involved process, so it is in your best
                            interests to fully understand the procedure, costs involved,
                            and to speak with a dental implant expert prior to making
                            a decision.
                        </p>
                    </div>
                </div>


                <div className='spgrid1'>
                    <div className='spinfosec'>
                        <div className='jushi'>
                            <h1>Benefits of Dental Implants</h1>
                            <h2>Are missing teeth making you feel self-conscious about your smile, or keeping you from eating comfortably each day?
                            </h2>
                        </div>
                        <div className='spinnergrid1' data-aos='fade-up'>
                            <p> <ul>
                                <li>Dental implants improve smile and comfort, offering a natural look and feel.</li><br />
                                <li>SpringFarm Dentists provide comprehensive treatments with specialized centers in South-West Sydney.</li><br />
                                <li>Frequently asked questions address various topics, including the reasons for getting an implant, available options, and associated costs.</li><br />
                                <li>Dental implants offer a permanent and stable alternative to dentures, with a high success rate and personalized solutions.</li><br />
                                <li>In addition to aesthetic benefits, implants maintain oral health, prevent bone loss, and restore proper chewing and speaking functions.</li><br />
                            </ul>
                            </p>
                            <img src={img3}></img>
                        </div>
                        <div data-aos='fade-up' >

                            <Servicecomponent />
                        </div>
                    </div>
                    <div className='spinnergrid2' data-aos='fade-up'>
                        <div className='sptypes'>
                        </div>
                    </div>




                </div>
            </div>
            <div className='spcc'>
                <div className='spc' data-aos='flip-up' >
                    <Spcarousel />
                </div>
            </div>
            <div className='spo' data-aos='fade-up'>
                <Spoffers />
            </div>



            <div className='dentalgrid'>
                <div>
                    <Serviceop />
                </div>
                <div>
                    <Spforms />
                </div>
            </div>



            <Book />
        </>
    )
}

export default Servicepage