import React from 'react'
import '../css/Veeners.css'
import img2 from '../images/veneers2.webp'
import img3 from '../images/veneers3.webp'
import img4 from '../images/veneers4.webp'
import img5 from '../images/veneers5.webp'
import img6 from '../svg/health-consultation.svg'
import img7 from '../svg/search.svg'
import img8 from '../svg/tooth.svg'
import img9 from '../svg/braces-teeth.svg'
import Faq from 'react-faq-component';
import '../css/Servicecomponent.css'
import { RiQuestionAnswerLine } from "react-icons/ri";
import Abovef from './Abovef'
import Spforms from './Spform'
import Serviceop from './Serviceop'
import img10 from '../svg/girlsmilesvg.svg'



const data = {
    rows: [
        {
            title: <h1 className="titleheading">Q. What are dental veneers?</h1>,
            content: (
                <span className="Spancon">
                    Dental veneers are thin shells made of porcelain or composite resin that are custom-designed to cover the front surface of teeth. They are used to improve the appearance of teeth by concealing imperfections such as stains, chips, gaps, and misalignment, resulting in a more attractive and uniform smile.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">Q. How long do veneers typically last?</h1>,
            content: (
                <span className="Spancon">
                    The longevity of veneers depends on several factors, including the type of veneers used, oral hygiene practices, and lifestyle choices. Porcelain veneers can last 10 to 15 years or more, while composite veneers may have a shorter lifespan. Regular dental check-ups and good oral care can help extend the life of your veneers.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">Q. Are veneers a suitable option for me?</h1>,
            content: (
                <span className="Spancon">
                    The suitability of veneers depends on your individual dental needs and goals. To determine if veneers are right for you, it's essential to schedule a consultation with a qualified dentist. They will evaluate your oral health, discuss your concerns, and recommend the most appropriate treatment plan, which may include veneers if they are a suitable option.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">Q. Where can I get veneers treatment?</h1>,
            content: (
                <span className="Spancon">
                    For professional veneers treatment, you can visit Spring Farm Dental. Our experienced team of dentists specializes in cosmetic dentistry, including veneers. We provide personalized consultations, custom veneer fabrication, and expert placement to enhance your smile. Contact Spring Farm Dental to schedule an appointment and discuss your veneer options.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">Q. How do I care for my veneers?</h1>,
            content: (
                <span className="Spancon">
                    Caring for your veneers is essential to maintain their appearance and longevity. You should:
                    <ul>
                        <li>Practice good oral hygiene, including regular brushing and flossing.</li>
                        <li>Avoid biting or chewing on hard objects or ice to prevent chipping or damage.</li>
                        <li>Limit consumption of staining foods and beverages to prevent discoloration.</li>
                        <li>Visit your dentist for regular check-ups and cleanings to ensure the health of your veneers and natural teeth.</li>
                    </ul>
                    Following these guidelines will help your veneers stay beautiful and functional.
                </span>
            ),
        },
    ],
};

const styles = {
    bgColor: 'white',
    titleTextSize: '3rem',
    rowTitleColor: "blue",
    rowContentColor: 'grey',
    arrowColor: "black",
    transitionDuration: "0.5s",
    timingFunc: "ease",
    rowContentColor: 'grey',
    rowContentPaddingTop: '4%'
};

const config = {
    animate: true,
};


const Veneers = () => {
    return (
        <div>
            <div className='spbanner'>
                <img className='spimg' src={img2}></img>
            </div>
            <div className='spbbwrap'>
                <div className='spbbottom' data-aos='fade-up'>
                    <span>Home {'>'} Services {'>'} Coesmetic Dentistry{'>'} Veneers </span>
                    <h1>VENEERS: Renew Your Smile</h1>
                    <p>If your smile isn't bringing you the joy you deserve, don't hesitate to connect with the welcoming team at Spring Farm Dental. We offer treatments that can enhance the appearance of your teeth and, most importantly, reignite your confidence in your smile.</p>
                    <p>Among the treatments we offer in Spring Farm, Sydney, are porcelain and composite veneers, which are highly popular for their ability to rejuvenate your smile at an affordable cost. Reach out to us today to schedule a consultation and discover the possibilities.</p>

                </div>
            </div>



            <>
        <div className='indiofferrwrap'>
            <div className='indiofferwrap'>
                <div className='indiofferwrap1'>

                    <h1>SPECIAL OFFER</h1>
                    <div className='indigrid'>
                        <div className='indiofferimg'>
                            <img src={img10} ></img>
                        </div>
                        <div className='indioffercon'>
                            <h2>Book Today
                                <br/>
                            &
                                <br/>
                            Claim Your
                                <br/>
                                Free Consultation
                                <br/>
                                &
                                <br/>
                                $500 off 
                                <br/>
                            (For all Health Fund Holders)</h2>

                            <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'> <button className='aboutbookbutton' >Book Now</button></a>
                            <h4>
                            We Accept all Types of Health Funds
                            </h4>
                            <br/>
                        
                           
                        </div>
                    

                    </div>

                </div>
                </div>
            </div>
        </>





            <div className='veneersgrid'>
                <div className='veneerscontent'>
                    <h1>Understanding Porcelain Veneers</h1>
                    <p>Porcelain veneers consist of a thin layer of porcelain custom-fitted to the front surface of a tooth or multiple teeth. These veneers are known for their durability and can last up to 10 years when properly cared for. The process of obtaining porcelain veneers typically involves multiple visits to our clinic to ensure a precise fit and outstanding results.</p>
                </div>
                <div className='veneersimg'>
                    <img src={img3}></img>
                </div>
            </div>


            <div className='veneersgrid'>
                <div className='veneersimg1'>
                    <img src={img4}></img>
                </div>
                <div className='veneerscontent'>
                    <h1>Is Porcelain Veneers Right for You?</h1>
                    <p>Porcelain veneers may be a suitable option if you:</p>
                    <ul>
                        <li>Have healthy teeth and gums, along with a good bite.</li>
                        <li>Feel dissatisfied with the color, shape, or size of your teeth.</li>
                        <li>Have sufficient tooth structure to support the veneers.</li>
                        <li>Desire porcelain veneers primarily for cosmetic enhancement.</li>
                        <li>Wish to address and improve the appearance of one or two specific teeth.</li>
                    </ul>
                </div>
            </div>

            <div className='veneersbanner'>
                <div className='veneersbancon'>

                    <h1>When to Consider a Dental Crown</h1>
                    <p>If your tooth is irreversibly damaged, contains a large filling, or has undergone a root canal, it may be necessary to contemplate a dental crown. This is because the tooth's enamel is compromised and necessitates more protection than what a porcelain veneer can offer.</p>
                </div>
            </div>
            <div className='veneersbanner1'>
                <div className='veneersbancon'>

                    <h1>Exploring Composite Veneers</h1>
                    <p>Composite veneers are crafted from a tooth-colored resin material, skillfully applied to the front surface of your teeth to enhance their appearance. This procedure is typically completed in a single visit to our clinic and is a more budget-friendly alternative to porcelain veneers. However, it's essential to note that composite veneers may not be as durable and might require more frequent replacements due to their composition.</p>
                </div>
            </div>
            <div className='veneersgrid'>
                <div className='veneersimg1'>
                    <img src={img5}></img>
                </div>
                <div className='veneerscontent'>
                    <h1>Is Composite Veneers the Right Choice for You?</h1>
                    <p>Composite veneers could be the ideal choice if you:</p>
                    <ul>
                        <li>Are seeking a budget-friendly option for cosmetic enhancements.</li>
                        <li>Have teeth that are chipped or stained and want to improve their appearance.</li>
                        <li>Desire to correct minor misalignments or close small gaps between teeth.</li>
                        <li>Seek a speedy solution for issues like small teeth, buck teeth, or overbite.</li>
                    </ul>
                </div>
            </div>
            <div className='veneersbanner2'>
                <div className='veneersbancon'>
                    <h1>Addressing Common Concerns with Veneers</h1>
                    <p>Undergoing a veneers procedure is typically irreversible, and once applied, they cannot be removed. We understand your hesitation, and if you're contemplating whether veneers are the right choice for you, allow us to address some common concerns to ease your decision-making process.</p>
                </div>
            </div>

            <div className="servicecomp" >
                <h1 className="faqheading" >Discover Your Smile's Secrets</h1>
                <h1 className="faqheadingd" >Frequently Asked Questions 	&nbsp; <RiQuestionAnswerLine /> </h1>
                <Faq
                    data={data}
                    styles={styles}
                    config={config} />
            </div>

            <div className='veneerscontentcard'>
                <div className='roundcardscolor'>
                    <div className='roundcardswrap'>
                        <h1>Our Simple Four-Step Porcelain Veneers Process</h1>
                        <p>Transforming your smile into a stunning Hollywood look is as easy as 1, 2, 3, 4 with our porcelain veneers process. This straightforward process is completed in just four visits, all conveniently in-chair at our clinic in Sydney's Spring Farm.</p>
                        <div class="orthocontainer">
                            <div class="orthotext">
                                <p> <h4>1. Initial Consultation</h4>
                                    During your initial consultation, you'll meet with one of our friendly and experienced dentists. They will assess whether porcelain veneers are the ideal solution for your teeth. This includes a thorough examination, X-rays, photography, and a discussion of your goals and expectations.</p>
                            </div>
                            <div class="orthocircle">
                                <img src={img6} alt="Your Image" />
                            </div>
                        </div>
                        <div class="orthocontainer">
                            <div class="orthotext1">
                                <p><h4>2. Planning</h4>
                                    After receiving your approval, we'll create a treatment plan. This involves selecting the right shade of porcelain to match your natural teeth, determining the quantity of veneers needed, and planning their optimal placement.</p>
                            </div>
                            <div class="orthocircle1">
                                <img src={img7} alt="Your Image" />
                            </div>
                        </div>
                        <div class="orthocontainer">
                            <div class="orthotext">
                                <p><h4>3. Tooth Preparation</h4>
                                    Prior to veneer placement, your teeth will be prepared. This may entail the removal of a small amount of enamel from the front surface to accommodate the veneers. Sedatives can be administered for your comfort if requested. After preparation, a digital impression will be taken to create a mold of your teeth. This mold is sent to the dental lab for custom-made veneers. Meanwhile, you'll receive temporary veneers to protect your teeth.</p>
                            </div>
                            <div class="orthocircle">
                                <img src={img8} alt="Your Image" />
                            </div>
                        </div>
                        <div class="orthocontainer">
                            <div class="orthotext1">
                                <p><h4>4. Veneer Placement</h4>
                                    Once your permanent veneers are ready, you'll return to our clinic for placement. Our team will affix them to your teeth using specialized dental cement. We'll make any necessary adjustments to ensure a perfect fit and a natural appearance.</p>
                            </div>
                            <div class="orthocircle1">
                                <img src={img9} alt="Your Image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='dentalgrid'>
                <div>
                    <Serviceop />
                </div>
                <div>
                    <Spforms />
                </div>
            </div>
            <Abovef />
        </div>

    )
}

export default Veneers