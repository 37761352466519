import React, { useState } from 'react';
import About from '../../About'
import { BsClock, BsPhone } from 'react-icons/bs'
import '../../../css/Location.css'
import ContentCard from '../../Contentcard'
import img1 from '../../../images/SPRINGFARMDENTAL.webp';
import img8 from '../../../svg/teeth-bacteria.svg';
import img3 from '../../../svg/braces-teeth.svg';
import img4 from '../../../svg/teeth-whitening.svg';
import img5 from '../../../svg/dentist-tool.svg';
import img6 from '../../../svg/teeth-implant.svg';
import img7 from '../../../svg/emergency-siren.svg';
import img9 from '../../../images/abtimg5.webp'
import img10 from '../../../images/location1.webp'
import img01 from '../../../images/img1.webp'
import img02 from '../../../images/img2.webp'
import img03 from '../../../images/img3.webp'
import img04 from '../../../images/img4.webp'
import img05 from '../../../images/img5.webp'
import img06 from '../../../images/img6.webp'
import img07 from '../../../images/location2.webp'
import img003 from '../../../images/payl.webp'
import img001 from '../../../images/girl.png'



const MountAnnan = () => {
    const [locationName, setLocationName] = useState(''); // Set the initial value to an empty string

    // Function to update the locationName state with the text from the h1 element
    const updateLocationName = () => {
        const spanElement = document.querySelector('.locationwrap span');
        if (spanElement) {
            setLocationName(spanElement.innerText);
        }
    };

    React.useEffect(() => {
        updateLocationName();
    }, []);


    return (
        <>
            <div className='locationhcolor'>
                <div className='locationwrap'>
                    <h1>Dental Clinic in <span>Mount Annan</span></h1>
                </div>
                <div className='locationstrip'>
                    <div>
                        <BsClock></BsClock>9:00-19:00  |  Open on weekends  |  <BsPhone></BsPhone>  <a href="tel://02 4603 1277">02 4603 1277</a>
                    </div>

                </div>
            </div>
            <div className='aboutwrap'>
                <img className='hoverimg' data-aos='fade-up' src={img3} />
                <div className='aboutcontainer' data-aos='fade-right'>
                    <img className='img1' src={img9} />
                </div>
                <div className='aboutcontent' data-aos='fade-left'>
                    <span className='smallheading' style={{ color: '#644222', fontWeight: '1000' }}>
                        About Us
                    </span>
                    <span className='mainheading' data-aos='fade-up'>
                        <h2>Best Dental Clinic, Near You, In {locationName}</h2>
                        <br />
                    </span>
                    <span className='contentz' data-aos='fade-up'>
                        <p>
                            Welcome to {locationName} Dental Clinic, your premier destination for top-notch dental care in the heart of {locationName}. Our dedicated team of experienced professionals is committed to providing you with a wide range of dental services in a comfortable and welcoming environment.
                        </p>
                        <p>
                            Whether you're seeking routine check-ups, cosmetic dentistry, or restorative treatments, we are here to serve all your oral health needs. Discover why we're the go-to choice for dental care in {locationName}.
                        </p>
                        <p>
                            Our dentists live in the local area and understand the intricacies of the community.
                        </p>
                        <p>
                            At {locationName} Dental Clinic, we understand that a healthy, confident smile is an essential part of your well-being. Our dedicated team of experienced professionals is committed to providing you with a wide range of dental services in a comfortable and welcoming environment.
                        </p>

                    </span>

                    <div className='aboutbutton'>
                    <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'> <button className='aboutbookbutton' data-aos='fade-up'>Book Now</button></a>
                        <span className='signature' data-aos='fade-up'>
                            Ankit
                        </span>
                    </div>
                </div>
            </div>
            <div className='serviceswrap'>
                <div className='servicescontent' data-aos='fade-up'>

                    <span className='theservices'>The Services</span>

                </div>
                <div className='servicesdiv'>
                    <div data-aos='fade-up'>
                        <ContentCard
                            imgSrc1={img1}
                            imgSrc={img8}
                            serviceName={`Check Up And Clean In ${locationName}`}
                            description="Ensure your oral health with our comprehensive check-up and cleaning services."
                            link="/Checkupandclean"
                            link2="/"
                        />
                    </div>
                    <div data-aos='fade-left'>
                        <ContentCard
                            imgSrc1={img1}
                            imgSrc={img3}
                            serviceName={`Veneers In ${locationName}`}
                            description="Transform your smile with porcelain or composite veneers for a flawless look."
                            link="/MountAnnanveneers"
                            link2="/"
                        />
                    </div>
                    <div data-aos='fade-right'>
                        <ContentCard
                            imgSrc1={img1}
                            imgSrc={img4}
                            serviceName={`Teeth Whitening In ${locationName}`}
                            description="Brighten your smile with our effective teeth whitening treatments."
                            link="/Teethwhite"
                            link2="/"
                        />
                    </div>
                    <div data-aos='fade-down'>
                        <ContentCard
                            imgSrc1={img1}
                            imgSrc={img5}
                            serviceName={`Orthodontics/Invisalign In ${locationName}`}
                            description="Achieve a perfectly aligned smile with our orthodontic solutions, including Invisalign."
                            link="/Orthodontics-Invisalign"
                            link2="/"
                        />
                    </div>
                    <div data-aos='fade-up'>
                        <ContentCard
                            imgSrc1={img1}
                            imgSrc={img6}
                            serviceName={`Dental Implants In ${locationName}`}
                            description="Restore missing teeth and regain your confidence with our dental implant expertise."
                            link="/MountAnnanimplants"
                            link2="/"
                        />
                    </div>
                    <div data-aos='fade-left'>
                        <ContentCard
                            imgSrc1={img1}
                            imgSrc={img7}
                            serviceName={`Emergency Dentistry In ${locationName}`}
                            description="We're here when you need us most, providing prompt and reliable emergency dental care."
                            link="/Emergency-Dentistry"
                            link2="/"
                        />
                    </div>

                </div>
            </div>



            <div className='emergencygridwrap'>
                <div className='emergencygrid'>
                    <div className='energencygridcon'>
                        <h1>Best Dental Clinic in {locationName}</h1>
                        <br />

                        <>
                            <p><strong>Expert Dental Team:</strong> Our experienced team is dedicated to the latest in dental advancements, ensuring top-notch care.</p>
                            <p><strong>Comprehensive Dental Services:</strong> From routine check-ups to advanced procedures, we offer a full range of services for your oral health needs in one convenient location.</p>
                            <p><strong>Cutting-Edge Technology:</strong> We invest in the latest dental tech for precision, efficiency, and your comfort.</p>
                            <p><strong>Patient-Centered Care:</strong> Your comfort is our priority. We provide personalized care to address your dental concerns and ensure a stress-free experience.</p>
                        </>




                        <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'> <button className='aboutbookbutton' data-aos='fade-up'>Book Now</button></a>

                    </div>
                    <div className='energencygridimg'>
                        <img src={img10}></img>
                    </div>
                </div>
            </div>

            <div className='wrapb' >
                <div className='afterheadings'>
                    <div className='loc'>

                        <div className='servicescontent' data-aos='fade-up'>

                            <span className='theservices'>Before & After</span>

                        </div>
                    </div>
                </div>
                <div className='beforegrid'>
                    <div className='imag'>
                        <img src={img02}></img>
                    </div>
                    <div className='imag'>
                        <img src={img01}></img>
                    </div>
                    <div className='imag'>
                        <img src={img03}></img>
                    </div>
                    <div className='imag'>
                        <img src={img04}></img>
                    </div>
                    <div className='imag'>
                        <img src={img05}></img>
                    </div>
                    <div className='imag'>
                        <img src={img06}></img>
                    </div>
                    {/* <div className='imag'>
                    <img src={img7}></img>
                </div> */}


                </div>
            </div>


            <div className='bookbg'>
                <div className='bor'></div>
                <div className='bookwrap'>
                    <div className='bookcontent' data-aos='fade-up'>
                        <h1 data-aos='fade-up' >Book Your Smile Makeover Appointment In {locationName} and Transform Your Dental Health!</h1>
                        <p data-aos='zoom-out' >Ready to take the first step toward a healthier, brighter smile? Booking an appointment at {locationName} is quick and easy. Simply fill out our online appointment request form or give us a call at  <a href="tel://+1233112223">+1233112223</a> to schedule your visit.
                            <br /><br />
                            Discover Dental Clinic in {locationName} - where local charm meets cutting-edge care! Our friendly team awaits you in our brand new clinic. Experience personalized service and leave with a beaming smile! Schedule your visit now!</p>
                        <div className='aboutbutton'>
                        <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'> <button className='aboutbookbutton' data-aos='fade-up'>Book Now</button></a> <br /><br /><br /><br />
                        </div>
                    </div>
                </div>
            </div>


            <div className='emergencygridwrap'>
                <div className='emergencygrid'>
                    <div className='energencygridcon'>
                        <h1>A Caring Dental Clinic in {locationName}</h1>
                        <br />
                        <br />
                        <p>Our dental clinic in {locationName} is more than just a place for treatments; it's a sanctuary where you can feel comfortable and confident in the care you receive. From the moment you walk through our doors, you'll be greeted by our warm and friendly staff who are here to assist you with any questions or concerns. We've designed our clinic to create a welcoming atmosphere, ensuring your visits to {locationName} are as stress-free as possible.</p>

                        <h2>Dr. Ankit Goyal: Your Trusted Partner in Oral Health</h2>

                        <p>At the heart of {locationName} Dental Clinic is Dr. Ankit Goyal, an experienced and compassionate dentist who is dedicated to your well-being. Dr. Ankit Goyal takes the time to understand your unique dental needs, concerns, and goals. They work closely with you to create a personalized treatment plan that aligns with your expectations and budget.</p>
                        <br />
                        <br />

                    </div>
                    <div className='energencygridimg'>
                        <img src={img07}></img>
                    </div>
                </div>
            </div>












            <div className='pbg' >
                <div className='pgh1' >

                    <h1 className='locc' >Pay with SUPERANNUATION</h1>
                    <br />
                </div>

                <div className='pcardwrap' >
                    <div className='pgrid'>

                        <div className='pcard'>
                            <h1>The Benefits Of Using Super To Pay For Treatment</h1>
                            <img src={img001}></img>
                            <ol className='pul'>
                                <li className='pli'>This is your money put aside for your future wellbeing. A price can not be put on the increased quality of life that comes from new teeth.</li>
                                <li className='pli'>Near 100% application success rate.</li>
                                <li className='pli'>No debt incurred as these are your funds No cap on the amount released.</li>
                                <li className='pli'>You can use a family member's Super if need be.</li>
                            </ol>
                        </div>
                    </div>


                    <div className='pgh1' >
                        <br />
                        <h1>Interest Free Payment Options</h1>
                        <p className='pgp' >As low as $10/week !</p>
                    </div>

                    <div className='pcard1wrap'>

                        <div className='pcard1'>

                            <img src={img003}></img>
                            <ol className='pul'>
                                <li className='pli'>Up to $30k, Interest FREE*</li>
                                <li className='pli'>No credit checks!*</li>
                                <li className='pli'>A 10% deposit is required.</li>
                                <li className='pli'>We can pre-approve on the spot if you have a [QLD] drivers license and you are employed for a minimum of 25 hours per week.</li>
                                <li className='pli'>Special approval process for patients who do not fit the above criteria.</li>
                            </ol>
                        </div>
                    </div>
                </div>

            </div>


            <div className='abovef'>
                <h1 data-aos='fade-up'>Getting an appointment at Dental Clinic in {locationName} is fast and simple: book online now!</h1>
                <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'> <button className='aboutbookbutton' data-aos='fade-up'>Book Now</button></a>
            </div>


        </>
    )
}

export default MountAnnan