import React from 'react';
import img1 from '../images/Frame5.webp';
import img2 from '../images/Frame6.webp';
import img3 from '../images/Frame7.webp';
import img4 from '../images/Frame8.webp';
import Faq from 'react-faq-component';
import { RiQuestionAnswerLine } from "react-icons/ri";

const data = {
    rows: [
        {
            title: <h1 className="titleheading">INNOVATIVE</h1>,
            content: (
                <span className="Spancon">
                    <img
                        src={img1}
                        style={{
                            height: "300px",
                            width: "300px",
                            margin: "0 auto", // Center horizontally
                            display: "block", // Ensures the margin property works as expected
                        }}
                        alt="Image Description"
                        loading="lazy"
                        preload="auto"
                    />
                    <br></br>
                    Your smile deserves the best, and that's what we deliver. We're not just a dental clinic; we're your gateway to the future of oral healthcare. Welcome to the next level of dentistry.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">PATIENT-CENTERED</h1>,
            content: (
                <span className="Spancon">
                    <img
                        src={img2}
                        style={{
                            height: "300px",
                            width: "300px",
                            margin: "0 auto", // Center horizontally
                            display: "block", // Ensures the margin property works as expected
                        }}
                        alt="Image Description"
                        loading="lazy"
                        preload="auto"
                    />
                    <br></br>
                    Every aspect of our practice is designed with your comfort and care in mind. We prioritize your individual needs and concerns, tailoring treatments and appointments to ensure a stress-free experience. 
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">SUPPORTIVE CLINIC</h1>,
            content: (
                <span className="Spancon">
                    <img
                        src={img3}
                        style={{
                            height: "300px",
                            width: "300px",
                            margin: "0 auto", // Center horizontally
                            display: "block", // Ensures the margin property works as expected
                        }}
                        alt="Image Description"
                        loading="lazy"
                        preload="auto"
                    />
                    <br></br>
                    Our dedicated team of skilled professionals is prepared to address urgent concerns or provide late-night support, making sure you're never alone in your time of need.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">YOUR FINANCIAL COMFORT</h1>,
            content: (
                <span className="Spancon">
                    <img
                        src={img4}
                        style={{
                            height: "300px",
                            width: "300px",
                            margin: "0 auto", // Center horizontally
                            display: "block", // Ensures the margin property works as expected
                        }}
                        alt="Image Description"
                        loading="lazy"
                        preload="auto"
                    />
                    <br></br>
                    We believe that everyone deserves a beautiful, healthy smile, and financial concerns should never stand in the way. With our flexible payment options, you can get the treatment you need without delay.
                </span>
            ),
        },
    ],
};

const styles = {
    bgColor: 'white',
    titleTextSize: '3rem',
    rowTitleColor: "blue",
    rowContentColor: 'grey',
    arrowColor: "black",
    transitionDuration: "0.5s",
    timingFunc: "ease",
    rowContentPaddingTop: '4%'
};

const config = {
    animate: true,
};

const AdvancedTechnology = () => {
    return (
        <div className="servicecomp">
            <h1 className="faqheading">The Pearl of Dentistry.</h1>
            <h1 className="faqheading">We Provide Special Treatment</h1>
            <h1 className="faqheadingd">Spring Farm Dental</h1>
            <Faq
                data={data}
                styles={styles}
                config={config}
            />
        </div>
    );
};

export default AdvancedTechnology;
