import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import img1 from '../svg/girlsmilesvg.svg';
import '../css/Dream.css';
import img3 from '../images/dentalhover1.webp';

const Dream = () => {
  useEffect(() => {
    AOS.init({
      duration: 3000,
      once: true, // Animations will only occur once
      mirror: false, // Animation will not be mirrored when scrolling down
    }); // Initialize AOS with a default duration of 1000ms
  }, []);

  return (
    <div className='emergencygridwrap0'>
      <img className='hoverimg' data-aos='fade-up' src={img3} />
      <div className='emergencygrid'>
        <div className='energencygridimg0'>
          <img src={img1}></img>
        </div>
        <div className='energencygridcon'>
          <h1>Same-Day Emergency Dental Appointments Available</h1>
          <p>Dealing with a dental emergency can be exceptionally stressful and overwhelming. It can be frightening to experience pain without knowing its cause or to have a dental emergency occur at an inconvenient time.</p>
          <p>We understand that dental emergencies can happen to anyone, at any moment. At our Spring Farm Dental clinic in Spring Farm, we are dedicated to delivering top-notch care to every patient who walks through our doors. Whether you need assistance after regular hours or on the same day, we will make every effort to accommodate you. We recognize that dental emergencies do not always align with our standard clinic hours.</p>
          <p>In many cases, we can provide treatment in a single visit. However, some conditions may require more extensive care.</p>
        </div>
      </div>
    </div>
  );
};

export default Dream;
