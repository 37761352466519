import React from 'react'
import '../css/Contactcard.css'
import img1 from '../gifs/Location.gif'
import img2 from '../gifs/Outgoing Call.gif'
import img3 from '../gifs/Calendar.gif'

const Contactcard = () => {
    return (
        <>
        <div className='ccwwrap'>
    <div className='ccwrap'>
        <div className='cc'>
            <img className='ccimg' src={img1} alt="Clinic Location" />
            <div className='cch1'>
                <h1>Locate Us</h1>
                <p>Find our clinic's convenient location and get directions to our state-of-the-art facility. We're dedicated to providing exceptional dental care in a welcoming environment. Visit us today and experience the difference!</p>
                <a href='#footeer'>
                    <button className='aboutbookbutton' data-aos='zoom-out'>
                        View Map
                    </button>
                </a>
            </div>
        </div>
        <div className='cc'>
            <img className='ccimg' src={img2} alt="Contact Information" />
            <div className='cch1'>
                <h1>Call Us</h1>
                <p>Have questions or need to schedule an appointment? Our friendly team is here to assist you. Call us at <h3>02 4603 1277</h3>, and we'll provide the information and support you need for your dental needs.</p>
                <a href="tel://02 4603 1277">
                    <button className='aboutbookbutton' data-aos='zoom-out'>
                        Call Now
                    </button>
                </a>
            </div>
        </div>
        <div className='cc'>
            <img className='ccimg' src={img3} alt="Appointment Booking" />
            <div className='cch1'>
                <h1>Book Us</h1>
                <p>Ready to prioritize your oral health? Schedule an appointment with us today. Our experienced team is eager to provide you with top-notch dental care. Your smile is our focus!</p>
                <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'> <button className='aboutbookbutton' data-aos='fade-up'>Book Now</button></a>

            </div>
        </div>
    </div>
</div>

        </>
    )
}

export default Contactcard