import React, { useEffect } from 'react'
import AOS from 'aos';
import img1 from '../images/dentalcheck.webp'
import img2 from '../images/dentalcheck2.webp'
import img3 from '../images/dentalcheck3.webp'
import '../css/Servicepage.css'
import Spforms from './Spform'
import Book from './Book'
import Checkupandcleanques from './Checkupandcleanques';
import Checkupandcleancost from './Checkupandcleancost';
import Checkupandcleanoffer from './Checkupandcleanoffer';
import Indioffer from './Indioffer';
import Serviceop from './Serviceop';

const Checkupandclean = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true, // Animations will only occur once
            mirror: true, // Animation will not be mirrored when scrolling down
        }); // Initialize AOS with a default duration of 1000ms
    }, []);
    return (
        <>
            <div className='spbanner'>
                <img className='spimg' src={img1}></img>
            </div>
            <div className='spbbwrap'>
                <div className='spbbottom' data-aos='fade-up'>
                    <span>Home {'>'} Services {'>'} General Dentistry{'>'} Check Up And Clean </span>
                    <h1>Check Up And Clean</h1>
                    <p>Dental hygiene encompasses the practice of thoroughly eliminating plaque, tartar, and other accumulations that adhere to the teeth and gum tissues. Typically administered by a dental hygienist or dentist, this procedure employs specialized instruments like scalers or ultrasonic devices to either scrape away or dislodge the buildup. Additionally, the cleaning process may involve a polishing step to eradicate surface stains and create a smoother tooth surface. Consistent teeth cleaning is a fundamental aspect of preserving optimal oral health and warding off potential dental issues, including cavities, gum diseases, and unpleasant halitosis.</p>
                </div>
            </div>
            <Indioffer />
            <div className='spwrap' data-aos='fade-up'>

                <div className='spg1' data-aos='fade-up'>
                    <img src={img2} data-aos='fade-right'></img>
                    <div className='spg1con' data-aos='fade-left'>
                        <h1>What is teeth cleaning?</h1>
                        <p>Teeth cleaning is a crucial dental procedure aimed at removing plaque, tartar, and other deposits from teeth and gums. Usually done by a dentist or dental hygienist using specialized tools, it can involve polishing to remove stains and create a smoother tooth surface. Regular teeth cleaning is vital for good oral hygiene, preventing issues like cavities, gum disease, and bad breath.
                            <br />
                            Prevention is key, and professional teeth cleaning helps avoid bigger problems. It's a quick, nearly painless process, followed by valuable tips for at-home oral care. We recommend visiting a dentist for cleaning and scaling at least every six months.
                        </p>
                    </div>
                </div>


                {/* <div className='spgrid' data-aos='fade-up'> */}
                <div className='spgrid1'>
                    <div className='spinfosec'>
                        <div className='jushi'>
                            <h1>Benefits Of Professional Dental Cleaning
                            </h1>
                        </div>
                        <div className='spinnergrid1' data-aos='fade-up'>
                            <p> <h2>Preventive Measures</h2>
                                <ul>

                                    <li>Regular check-ups catch tooth decay and cavities early, preventing more extensive treatment.</li>


                                </ul>

                                <h2>Teeth Polishing</h2>
                                <ul>
                                    <li>Teeth Polishing: We use advanced tools to polish teeth, removing plaque and tartar for a fresh, shiny look.</li>

                                </ul>

                                <h2>Gum Disease Prevention</h2>
                                <ul>
                                    <li>Gum Disease Prevention: Routine cleanings reduce the risk of gum disease by removing plaque and tartar buildup.</li>

                                </ul>

                                <h2>Early Oral Cancer Detection</h2>
                                <ul>
                                    <li>Early Cancer Detection: During check-ups, we screen for oral cancer, ensuring early detection and treatment.</li>

                                </ul>

                                <h2>Oral Health Maintenance</h2>
                                <ul>
                                    <li>Oral Health Maintenance: Regular visits eliminate stains and buildup, enhancing appearance and oral health.</li>
                                </ul>
                            </p>
                            <img src={img3}></img>
                        </div>
                        <div data-aos='fade-up' >

                            <Checkupandcleanques />
                        </div>
                    </div>
                    <div className='spinnergrid2' data-aos='fade-up'>
                        <div className='sptypes'>
                        </div>
                    </div>
                </div>
                {/* <div className='spgrid2'>
                        <div className='spotherservices'>
                            <h1>Care Services</h1>
                            <br />
                            <p>__________</p>
                            <br />
                            <div className='spservicename' data-aos='fade-up' > <LiaToothSolid className='spicon' /> &nbsp; Teeth Whitening</div>
                            <div className='spservicename' data-aos='fade-up' > <LiaToothSolid className='spicon' /> &nbsp; Teeth Whitening</div>
                            <div className='spservicename' data-aos='fade-up' > <LiaToothSolid className='spicon' /> &nbsp; Teeth Whitening</div>
                            <div className='spservicename' data-aos='fade-up' > <LiaToothSolid className='spicon' /> &nbsp; Teeth Whitening</div>
                            <div className='spservicename' data-aos='fade-up' > <LiaToothSolid className='spicon' /> &nbsp; Teeth Whitening</div>
                            <div className='spservicename' data-aos='fade-up' > <LiaToothSolid className='spicon' /> &nbsp; Teeth Whitening</div>
                            <div className='spservicename' data-aos='fade-up' > <LiaToothSolid className='spicon' /> &nbsp; Teeth Whitening</div>
                        </div>
                        <Spforms />
                    </div> */}
            </div>
            {/* </div> */}
            <div className='spcc'>

                <div className='spc' data-aos='flip-up' >
                    <Checkupandcleanoffer />
                </div>
            </div>
            <div className='spo' data-aos='fade-up'>
                <Checkupandcleancost />
            </div>

            <div className='dentalgrid'>
                <div>
                    <Serviceop />
                </div>
                <div>
                    <Spforms />
                </div>
            </div>

            <Book />
        </>
    )
}

export default Checkupandclean