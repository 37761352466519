import React from 'react'
import img1 from '../images/image(5).webp'
import '../css/Checkupandcleanoffer.css'

const Checkupandcleanoffer = () => {
    return (
        <div className='cleanofferwrap' >
            <div className='cleanoffer'>
                <div className='cleanofferimg' >

                    <img src={img1}></img>
                </div>

                <div className='cleanoffercontent'>
                    <h1>Dental Check-Up and Clean Cost</h1>
                    <hr/>
                    <p>We are committed to ensuring that oral healthcare is accessible to everyone. That's why we take pride in offering multiple payment options for your convenience.</p>

                    <p>We accept payments through cash and major credit cards, and we also work with private health insurance plans. Additionally, we provide flexible payment plans to accommodate your specific needs.</p>
                    <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'> <button className='aboutbookbutton' data-aos='fade-up'>Get Appointment</button></a>

                </div>

            </div>
        </div>
    )
}

export default Checkupandcleanoffer