import React, { useState } from 'react'
import { BsClock, BsPhone } from 'react-icons/bs'
import ContentCard from '../../Contentcard';
import img4 from '../../../svg/broken-tooth.svg';
import img2 from '../../../svg/broken-teeth-pain.svg';
import img3 from '../../../svg/dental-treatment.svg';
import img1 from '../../../images/SPRINGFARMDENTAL.webp';
import img9 from '../../../images/energencybanner1.webp';
import img5 from '../../../svg/teeth-drilling.svg';
import img6 from '../../../svg/medical-appointment.svg';
import img7 from '../../../svg/remove-tooth.svg';
import img8 from '../../../svg/dental-drill.svg';
import img10 from '../../../images/emergency3.webp';
import Faq from 'react-faq-component';
import { RiQuestionAnswerLine } from "react-icons/ri";
import Serviceop from '../../Serviceop';
import Spforms from '../../Spform';
import Discover from '../../Discover';
import Abovef from '../../Abovef';


const data = {

    rows: [
        {
            title: <h1 className="titleheading">Q. What is emergency dentistry?</h1>,
            content: (
                <span className="Spancon">
                    Emergency dentistry is a branch of dental care that focuses on providing immediate treatment and care for dental issues that require urgent attention. These issues often include severe toothaches, dental injuries, knocked-out teeth, and other dental emergencies that need prompt evaluation and treatment to alleviate pain and prevent further complications.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">Q. When should I seek emergency dental care?</h1>,
            content: (
                <span className="Spancon">
                    You should seek emergency dental care if you experience any of the following:
                    <ul>
                        <li>Severe toothache or persistent dental pain</li>
                        <li>Knocked-out tooth or dental injury</li>
                        <li>Broken or chipped teeth with exposed nerves</li>
                        <li>Uncontrolled bleeding from the mouth or gums</li>
                        <li>Swelling of the face or jaw due to a dental infection</li>
                        <li>Lost dental fillings or crowns causing discomfort</li>
                    </ul>
                    Contact your emergency dentist immediately for guidance on how to proceed in such situations.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">Q. What should I do in a dental emergency before seeing a dentist?</h1>,
            content: (
                <span className="Spancon">
                    In a dental emergency, it's essential to take immediate action. Here are some steps to follow before seeing a dentist:
                    <ul>
                        <li>If a tooth is knocked out, handle it by the crown (not the root) and try to reinsert it into the socket. If that's not possible, store it in a container of milk or your saliva until you see the dentist.</li>
                        <li>Rinse your mouth with warm water to clean it and remove debris if you have a dental injury.</li>
                        <li>If you have severe bleeding, apply gentle pressure with gauze or a clean cloth to the bleeding area.</li>
                        <li>If you have a toothache, floss gently to remove any potential food particles stuck between your teeth.</li>
                    </ul>
                    These steps can help mitigate the situation until you can get to an emergency dentist.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">Q. Is emergency dentistry covered by dental insurance?</h1>,
            content: (
                <span className="Spancon">
                    Whether emergency dentistry is covered by dental insurance depends on your specific insurance plan. Some dental insurance policies may cover emergency dental services, while others may have limitations or require pre-authorization. It's essential to check your insurance policy or contact your insurance provider to understand your coverage for emergency dental care.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">Q. How can I find an emergency dentist near me?</h1>,
            content: (
                <span className="Spancon">
                    If you're near Spring Farm Dental and require emergency dental assistance, don't hesitate to call us directly. Our dedicated team is available to assist you with your urgent dental needs, including same-day appointments and after-hours emergency care.
                </span>
            ),
        },
    ],

    // Use the 'faqs' array to display your FAQs in your preferred frontend framework or JavaScript environment.

};

const styles = {
    bgColor: 'white',
    titleTextSize: '3rem',
    rowTitleColor: "blue",
    rowContentColor: 'grey',
    arrowColor: "black",
    transitionDuration: "0.5s",
    timingFunc: "ease",
    rowContentColor: 'grey',
    rowContentPaddingTop: '4%'
};

const config = {
    animate: true,
};


const Appinemergency = () => {


    const [locationName, setLocationName] = useState(''); // Set the initial value to an empty string

    // Function to update the locationName state with the text from the h1 element
    const updateLocationName = () => {
        const spanElement = document.querySelector('.locationwrap span');
        if (spanElement) {
            setLocationName(spanElement.innerText);
        }
    };

    React.useEffect(() => {
        updateLocationName();
    }, []);


    return (
        <div>
            <header>
                <div className='locationhcolor'>
                    <div className='locationwrap'>
                        <h1>Energency Dentistry in <span>Appin</span></h1>
                    </div>
                    <div className='locationstrip'>
                        <div>
                            <BsClock></BsClock>9:00-19:00  |  Open on weekends  |  <BsPhone></BsPhone>  <a href="tel://02 4603 1277">02 4603 1277</a>
                        </div>

                    </div>
                </div>

            </header>
            <div className='energencycolor'>
                <div className='emergencywrap'>
                    <h1>Common Dental Emergencies</h1>
                    <p>If you're experiencing severe pain or bleeding from the mouth, it's essential to seek immediate dental assistance. Various emergency dental services are available to address dental pain or trauma.</p>
                    <p>Some of the most frequent types of emergency dental services that we can provide include:</p>
                </div>
            </div>



            <div className='emergencycolor'>
                <div className='servicesdiv'>
                    <div data-aos='fade-up'>
                        <ContentCard
                            imgSrc1={img1}
                            imgSrc={img2}
                            serviceName="Tooth Aches"
                            description="If you're experiencing severe pain or bleeding from the mouth, it's essential to seek immediate dental assistance."
                            link="/"
                        />
                    </div>
                    <div data-aos='fade-left'>
                        <ContentCard
                            imgSrc1={img1}
                            imgSrc={img4}
                            serviceName="Broken Or Chipped Tooth"
                            description="If you're experiencing severe pain or bleeding from the mouth, it's essential to seek immediate dental assistance."
                            link="/"
                        />
                    </div>
                    <div data-aos='fade-right'>
                        <ContentCard
                            imgSrc1={img1}
                            imgSrc={img3}
                            serviceName="Dental Trauma Treatment"
                            description="If you're experiencing severe pain or bleeding from the mouth, it's essential to seek immediate dental assistance."
                            link="/"
                        />
                    </div>
                    <div data-aos='fade-down'>
                        <ContentCard
                            imgSrc1={img1}
                            imgSrc={img5}
                            serviceName="Abscess Treatment"
                            description="If you're experiencing severe pain or bleeding from the mouth, it's essential to seek immediate dental assistance."
                            link="/"
                        />
                    </div>
                    <div data-aos='fade-up'>
                        <ContentCard
                            imgSrc1={img1}
                            imgSrc={img6}
                            serviceName="Orthodontic Emergencies"
                            description="If you're experiencing severe pain or bleeding from the mouth, it's essential to seek immediate dental assistance."
                            link="/"
                        />
                    </div>
                    <div data-aos='fade-left'>
                        <ContentCard
                            imgSrc1={img1}
                            imgSrc={img7}
                            serviceName="Emergency Tooth Extraction"
                            description="If you're experiencing severe pain or bleeding from the mouth, it's essential to seek immediate dental assistance."
                            link="/"
                        />
                    </div>
                    <div data-aos='fade-left'>
                        <ContentCard
                            imgSrc1={img1}
                            imgSrc={img8}
                            serviceName="Emergency Prosthetic Restoration"
                            description="If you're experiencing severe pain or bleeding from the mouth, it's essential to seek immediate dental assistance."
                            link="/"
                        />
                    </div>
                </div>
            </div>


            <div className='emergencygridwrap'>
                <div className='emergencygrid'>
                    <div className='energencygridcon'>
                        <h1>Same-Day Emergency Dental Appointments Available in {locationName}</h1>
                        <p>Dealing with a dental emergency can be exceptionally stressful and overwhelming. It can be frightening to experience pain without knowing its cause or to have a dental emergency occur at an inconvenient time.</p>
                        <p>We understand that dental emergencies can happen to anyone, at any moment. At our Spring Farm clinic in Sydney, we are dedicated to delivering top-notch care to every patient who walks through our doors. Whether you need assistance after regular hours or on the same day, we will make every effort to accommodate you. We recognize that dental emergencies do not always align with our standard clinic hours.</p>
                        <p>In many cases, we can provide treatment in a single visit. However, some conditions may require more extensive care.</p>
                        <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'> <button className='aboutbookbutton' data-aos='fade-up'>Book Now</button></a>

                    </div>
                    <div className='energencygridimg'>
                        <img src={img10} alt="Image 10" />
                    </div>
                </div>
            </div>


            <div className='dentallocbancol'>
                <div className='dentallocban'>
                    <div>

                        <h1>Always Available Emergency Dentistry in {locationName}: We Are Here For You</h1>
                    </div>
                    <div>

                        <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'> <button className='aboutbookbutton' data-aos='zoom-out'>Book Now</button></a>


                    </div>

                    <div>

                        <a href="tel://02 4603 1277">
                            <button className='aboutbookbutton' data-aos='zoom-out'>
                                Call Now
                            </button>
                        </a>

                    </div>
                </div>
            </div>

            <div className="servicecomp">
                <h1 className="faqheading">Discover Your Smile's Secrets</h1>
                <h1 className="faqheadingd">Frequently Asked Questions 	&nbsp; <RiQuestionAnswerLine /> </h1>
                <Faq
                    data={data}
                    styles={styles}
                    config={config}
                />
            </div>

            <Discover />

            <div className='dentalgrid'>
                <div>
                    <Serviceop />
                </div>
                <div>
                    <Spforms />
                </div>
            </div>
            <div className='af'>
      <Abovef />
    </div>

        </div>
    )
}

export default Appinemergency