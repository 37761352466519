import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../css/Discover.css';
import img1 from '../images/abtimg1.webp';
import img3 from '../images/dentalhover1.webp';

const Discover = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true, // Animations will only occur once
      mirror: false, // Animation will not be mirrored when scrolling down
    }); // Initialize AOS with a default duration of 1000ms
  }, []);

  return (
    <div className='discoverwrap'>
      <img className='hoverimg' src={img3} data-aos='fade-up' alt='Discover' />
      <div className='discovergrid'>
        <div className='discoverimg' data-aos='fade-right'>
          <img src={img1} alt='Discover' />
        </div>
        <div className='discovercontent' data-aos='fade-left'>
          <span data-aos='fade-up'>Spring Farm Dental</span>
          <h1 data-aos='fade-up'>Welcome to Our Dental Practice</h1>
          <p className='discoverpara' data-aos='fade-up'>
            At our dental clinic, we are dedicated to providing you with the highest quality of dental care. Our experienced team of dentists and staff are committed to ensuring your oral health and well-being.
            <br />
            <br />
            We believe that a healthy smile is a beautiful smile, and we offer a range of services to help you achieve and maintain your best oral health. From routine check-ups and cleanings to advanced treatments like veneers, teeth whitening, and orthodontics, we have your dental needs covered.
            <br />
            <br />
            Our goal is to make your dental experience comfortable and stress-free. Whether you're visiting us for a regular check-up or require emergency dentistry, we're here to provide you with the care you deserve.
            <br />
            <br />
            Discover the difference of our dental practice and schedule your appointment today. Your smile is our priority.
          </p>

          <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'> <button className='aboutbookbutton' data-aos='fade-up'>Book Now</button></a>

        </div>
      </div>
    </div>
  );
};

export default Discover;
