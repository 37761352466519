import React from "react";
import Faq from 'react-faq-component';
import '../css/Servicecomponent.css'
import { RiQuestionAnswerLine } from "react-icons/ri";
const data = {

  rows: [
    {
      title: <h1 className="titleheading">Q. Why Should I get a Dental Implant ?</h1>,
      content: <span className="Spancon" ><h3>Dental implants are beneficial over several other more traditional forms of tooth replacement because of the following reasons:</h3>
      <ul>
          <li>✔ They last longer than bridges or dentures</li><br/>
          <li>✔ They prevent bone loss, which is often associated with missing teeth</li><br/>
          <li>✔ They are an ideal replacement option for removable dentures</li><br/>
          <li>✔ There is no wear and tear on surrounding teeth</li><br/>
          <li>✔ They look natural and will only be identifiable by dentists</li><br/>
          <li>✔ They allow you to eat hard foods without any issues</li><br/>
          <li>✔ They can improve your self-confidence</li><br/>
          <li>✔ There is no age restriction: anyone can have dental implants</li><br/>
      </ul></span>,
    },
    {
      title: <h1 className="titleheading">Q. Is a Dental Implant Right for Me ?
      </h1>,
      content: <span className="Spancon" > <h3>Dental implants, unlike teeth whitening, isn’t a one size fits all procedure, so you will need to spend some time with your dentist assessing whether or not you are a suitable candidate for the procedure.</h3>
      <h3>The three most important aspects of eligibility are:</h3>
      <ul>
          <li>&#10004; You must have a reasonable amount of dense bone tissue in your jaw</li><br/>
          <li>&#10004; You must not have periodontal disease (this is a form of gum disease)</li><br/>
          <li>&#10004; You must not be a smoker, or at least be willing to give up smoking</li><br/>
      </ul>
      Our team of experienced, Sydney based, dental implant specialists can help you decide whether dental implants are the best solution for your case – providing context as well as alternatives where possible. If you’re still not sure about whether you need dental implants you can book a FREE* consultation (*Or Health Fund only, no gap consultation) at either of our Sydney dental clinics: Bondi Junction or North Sydney  to discuss your case with someone from our experienced dentist team.
      </span>,
    },
    {
      title: <h1 className="titleheading">Q. What are the most common dental implant problems ?
      </h1>,
      content: <span className="Spancon" ><h3>Occasionally we see dental implants that have failed to integrate with the bone. We replace these with a larger implant, which usually resolves the problem.
      On very rare occasions, gum infections or broken implants can occur, but with proper care the risk of these issues are almost entirely eliminated.
      </h3></span>,
    },
    {
      title: <h1 className="titleheading">Q. What are the different types of Dental Implants ?
      </h1>,
      content: <span className="Spancon" > <h3>Dental implants, unlike teeth whitening, isn’t a one size fits all procedure, so you will need to spend some time with your dentist assessing whether or not you are a suitable candidate for the procedure.</h3>
      <h4>The three most important aspects of eligibility are:</h4>
      <ul>
          <li>&#10004; You must have a reasonable amount of dense bone tissue in your jaw</li><br/>
          <li>&#10004; You must not have periodontal disease (this is a form of gum disease)</li><br/>
          <li>&#10004; You must not be a smoker, or at least be willing to give up smoking</li><br/>
      </ul></span>,
    },
    {
    title: <h1 className="titleheading">Q. Lorem ipsum dolor sit amet</h1>,
    content: <span className="Spancon" >Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed tempor sem. Aenean vel turpis feugiat,
        ultricies metus at, consequat velit. Curabitur est nibh, varius in tellus nec, mattis pulvinar metus.
        In maximus cursus lorem, nec laoreet velit eleifend vel. Ut aliquet mauris tortor, sed egestas libero interdum vitae.
        Fusce sed commodo purus, at tempus turpis.</span>,
    },
    {
      title: <h1 className="titleheading">Q. Lorem ipsum dolor sit amet</h1>,
      content: <span className="Spancon" >Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed tempor sem. Aenean vel turpis feugiat,
        ultricies metus at, consequat velit. Curabitur est nibh, varius in tellus nec, mattis pulvinar metus.
        In maximus cursus lorem, nec laoreet velit eleifend vel. Ut aliquet mauris tortor, sed egestas libero interdum vitae.
        Fusce sed commodo purus, at tempus turpis.</span>,
    },
  ],
};

const styles = {
  bgColor: 'white',
  titleTextSize: '3rem',
  rowTitleColor: "blue",
  rowContentColor: 'grey',
  arrowColor: "black",
  transitionDuration: "0.5s",
  timingFunc: "ease",
  rowContentColor: 'grey',
  rowContentPaddingTop: '4%'
};

const config = {
  animate: true,
};


const Servicecomponent = () => {
  return (
  <>
    <div className="servicecomp" >
      <h1 className="faqheading" >Discover Your Smile's Secrets</h1>
      <h1 className="faqheadingd" >Frequently Asked Questions 	&nbsp; <RiQuestionAnswerLine /> </h1>
      <Faq
        data={data}
        styles={styles}
        config={config} />
    </div>
  </>
  );
};

export default Servicecomponent;
