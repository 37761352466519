import React from 'react'

const Attributions = () => {
    return (
    <>

<a href="https://www.flaticon.com/free-icons/dental" title="dental icons">Dental icons created by dr.iconsart - Flaticon</a><br/>
<a href="https://www.flaticon.com/free-icons/dental-report" title="dental report icons">Dental report icons created by adriansyah - Flaticon</a><br/>
<a href="https://www.flaticon.com/free-icons/dentist" title="dentist icons">Dentist icons created by Icongeek26 - Flaticon</a><br/>
<a href="https://www.freepik.com/free-photo/female-dentist-examining-little-girl-teeth-dental-office_27527214.htm?query=dental%20clinic%20luxury#from_view=detail_alsolike">Image by ArtPhoto_studio</a> on Freepik<br/>
Image by <a href="https://pixabay.com/users/pexels-2286921/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1867092">Pexels</a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1867092">Pixabay</a><br/>
<a href="https://www.freepik.com/free-photo/dentist-with-smile_5904229.htm#query=dentist%20banner&position=8&from_view=keyword&track=ais">Image by Racool_studio</a> on Freepik<br/>
Photo by cottonbro studio: https://www.pexels.com/photo/health-workers-wearing-face-mask-3957987/<br/>
Photo by Cdn Pages on Unsplash<br/>
Photo by Tima Miroshnichenko: https://www.pexels.com/photo/woman-in-white-long-sleeve-shirt-using-dental-equipment-5355827/<br/>

Photo by Andrea Piacquadio: https://www.pexels.com/photo/people-wearing-white-shirt-working-on-woman-s-teeth-3779709/<br/>
Photo by Karolina Grabowska: https://www.pexels.com/photo/people-pointing-a-dental-shade-guide-6627281/<br/>
Photo by cottonbro studio: https://www.pexels.com/photo/an-x-ray-image-of-a-jawbone-in-the-computer-6502017/<br/>

<a href="https://www.flaticon.com/free-animated-icons/health" title="health animated icons">Health animated icons created by Freepik - Flaticon</a><br/>
<a href="https://www.flaticon.com/free-animated-icons/medicine" title="medicine animated icons">Medicine animated icons created by Freepik - Flaticon</a><br/>
<a href="https://www.flaticon.com/free-animated-icons/heart" title="heart animated icons">Heart animated icons created by Freepik - Flaticon</a><br/>
<a href="https://www.flaticon.com/free-animated-icons/dentist" title="dentist animated icons">Dentist animated icons created by Freepik - Flaticon</a><br/>
<a href="https://www.flaticon.com/free-animated-icons/teeth" title="teeth animated icons">Teeth animated icons created by Freepik - Flaticon</a><br/>
<a href="https://www.flaticon.com/free-animated-icons/tooth" title="tooth animated icons">Tooth animated icons created by Freepik - Flaticon</a><br/>

https://script.google.com/macros/s/AKfycbxNNZuUb3hN41x_Uysxsolqz8apYr2PVrRsx_GJqgGnDjrIeGwBdBzIimXWf51PKfvFJA/exec<br/>

Photo by Karolina Grabowska: https://www.pexels.com/photo/dental-teeth-cast-models-on-table-6627601/<br/>
Photo by Anna Shvets: https://www.pexels.com/photo/man-sitting-on-dental-chair-3845810/<br/>
Photo by cottonbro studio: https://www.pexels.com/photo/close-up-shot-of-dental-implant-model-6502306/<br/>
Photo by Andrea Piacquadio: https://www.pexels.com/photo/man-in-white-dress-shirt-wearing-white-framed-eyeglasses-3779705/<br/>

Photo by Andrea Piacquadio: https://www.pexels.com/photo/woman-in-collared-shirt-774909/<br/>
Photo by Cedric Fauntleroy: https://www.pexels.com/photo/a-dentist-checking-a-woman-s-mouth-4269692/<br/>

<a href="https://iconscout.com/lotties/location" target="_blank">Free Location Animated Icon</a> by <a href="https://iconscout.com/contributors/iconscout" target="_blank">IconScout Store</a><br/>
<a href="https://iconscout.com/lotties/outgoing-call" target="_blank">Free Outgoing Call Animated Icon</a> by <a href="https://iconscout.com/contributors/patchpo" target="_blank">Silvia Porcu</a><br/>
<a href="https://iconscout.com/lotties/calendar" target="_blank">Free Calendar Animated Icon</a> by <a href="https://iconscout.com/contributors/iconscout">IconScout Store</a> on <a href="https://iconscout.com">IconScout</a><br/>
<a href="https://iconscout.com/lotties/customer-service-chat" target="_blank">Free Customer service chat Animated Icon</a> on <a href="https://iconscout.com">IconScout</a><br/>
<a href="https://iconscout.com/lotties/female" target="_blank">Free Female Call center Operator Animated Icon</a> by <a href="https://iconscout.com/contributors/iamjb">jbzkyy</a> on <a href="https://iconscout.com">IconScout</a><br/>

Photo by Anna Shvets: https://www.pexels.com/photo/man-sitting-on-dental-chair-3845808/3<br/>
<a href="https://www.freepik.com/free-photo/portrait-beautiful-patient-holding-orthodontic-retainers-dental-clinic_28031077.htm#query=Orthodontics&position=7&from_view=search&track=sph">Image by tonodiaz</a> on Freepik<br/>
<a href="https://www.freepik.com/free-photo/white-teeth_6452437.htm#page=3&query=tooth%20with%20braces&position=11&from_view=search&track=ais">Image by Racool_studio</a> on Freepik<br/>

<a href="https://www.freepik.com/free-photo/dentist-holding-dental-plastic-model-with-braces_10227728.htm#page=3&query=tooth%20with%20braces&position=18&from_view=search&track=ais#position=18&page=3&query=tooth%20with%20braces">Image by wayhomestudio</a> on Freepik<br/>

<a href="https://iconscout.com/icons/broken-tooth" target="_blank">Free Broken tooth Icon</a> by <a href="https://iconscout.com/contributors/iconmarketpk">IconMarket</a> on <a href="https://iconscout.com">IconScout</a><br/>
<a href="https://iconscout.com/icons/broken-teeth-pain" target="_blank">Free Broken Teeth Pain Icon</a> by <a href="https://iconscout.com/contributors/sooodesign">Sooodesign .</a> on <a href="https://iconscout.com">IconScout</a><br/>
<a href="https://iconscout.com/icons/dental-treatment" target="_blank">Free Dental Treatment Icon</a> on <a href="https://iconscout.com">IconScout</a><br/>
<a href="https://iconscout.com/icons/teeth-drilling" target="_blank">Free Teeth Drilling Icon</a> by <a href="https://iconscout.com/contributors/vectors-tank">Vectors Tank</a> on <a href="https://iconscout.com">IconScout</a><br/>
<a href="https://iconscout.com/icons/medical-appointment" target="_blank">Free Medical appointment Icon</a> by <a href="https://iconscout.com/contributors/iconmarketpk">IconMarket</a> on <a href="https://iconscout.com">IconScout</a><br/>
<a href="https://iconscout.com/icons/remove-tooth" target="_blank">Free Remove Tooth Icon</a> by <a href="https://iconscout.com/contributors/cuputo">cuputo</a> on <a href="https://iconscout.com">IconScout</a><br/>
<a href="https://iconscout.com/icons/dental-drill" target="_blank">Free Dental drill Icon</a> by <a href="https://iconscout.com/contributors/iconscout" target="_blank">IconScout Store</a><br/>
Photo by Arvind Philomin: https://www.pexels.com/photo/dentist-checking-teeth-of-a-person-12148417/<br/>
Photo by Andrea Piacquadio: https://www.pexels.com/photo/photo-of-woman-looking-at-the-mirror-774866/<br/>
<a href="https://www.freepik.com/free-photo/dentist-examining-female-patient-with-teeth-shades_8897011.htm#query=teeth%20veneers&position=22&from_view=search&track=ais">Image by wavebreakmedia_micro</a> on Freepik<br/>
Photo by Pavel Danilyuk: https://www.pexels.com/photo/different-shades-of-veneers-6812494/<br/>
<a href="https://www.freepik.com/free-photo/dentist-white-teeth_25948219.htm#query=teeth%20veneers&position=29&from_view=search&track=ais">Image by Holiak</a> on Freepik<br/>
<a href="https://www.freepik.com/free-photo/woman-smiling_978756.htm#query=teeth%20veneers&position=40&from_view=search&track=ais">Image by asier_relampagoestudio</a> on Freepik<br/>
<a href="https://www.freepik.com/free-photo/dentist-examining-female-patient-with-teeth-shades_8404382.htm#query=teeth%20veneers&position=11&from_view=search&track=ais">Image by wavebreakmedia_micro</a> on Freepik<br/>

<a href="https://www.freepik.com/free-photo/young-female-patient-with-open-mouth-examining-dental-inspection-dentist-office_18017002.htm#query=dental%20check%20up&position=45&from_view=search&track=ais">Image by artursafronovvvv</a> on Freepik<br/>

<a href="https://www.freepik.com/free-photo/dentist-examining-female-patient-with-tools_8897030.htm#page=2&query=teeth%20fillings&position=35&from_view=search&track=ais">Image by wavebreakmedia_micro</a> on Freepik<br/>

<a href="https://www.freepik.com/free-photo/dentist-process-dental-services-dental-office-dental-treatment_26144927.htm#query=root%20canal&position=43&from_view=search&track=ais">Image by Kireyonok_Yuliya</a> on Freepik<br/>

<a href="https://www.freepik.com/free-photo/dentist-examining-mouth-patient_10227704.htm#query=teeth%20crown&position=14&from_view=search&track=ais">Image by wayhomestudio</a> on Freepik<br/>

<a href="https://www.freepik.com/free-photo/dentist-examining-patient-teeth-with-mouth-mirror_8403853.htm#query=teeth%20removal&position=23&from_view=search&track=ais">Image by wavebreakmedia_micro</a> on Freepik<br/>

<a href="https://www.freepik.com/free-photo/little-boy-patient-dentist_10298805.htm#query=children%20dentistry&position=30&from_view=search&track=ais">Image by senivpetro</a> on Freepik<br/>
<a href="https://www.freepik.com/free-photo/woman-patient-dentist_2534121.htm#query=teeth%20whitening&position=3&from_view=search&track=ais">Image by senivpetro</a> on Freepik<br/>


<a href="https://www.freepik.com/free-photo/dentist-with-smile_5904233.htm#query=teeth%20whitening&position=9&from_view=search&track=ais">Image by Racool_studio</a> on Freepik<br/>
<a href="https://iconscout.com/icons/teeth-implant" target="_blank">Free Teeth implant  Icon</a> by <a href="https://iconscout.com/contributors/iconscout">IconScout Store</a> on <a href="https://iconscout.com">IconScout</a><br/>
<a href="https://iconscout.com/icons/braces-teeth" target="_blank">Free Braces Teeth  Icon</a> by <a href="https://iconscout.com/contributors/cuputo">cuputo</a> on <a href="https://iconscout.com">IconScout</a><br/>
<a href="https://iconscout.com/icons/teeth-whitening" target="_blank">Free Teeth whitening  Icon</a> by <a href="https://iconscout.com/contributors/iconscout" target="_blank">IconScout Store</a><br/>
<a href="https://iconscout.com/icons/dentist-tool" target="_blank">Free Dentist Tool  Icon</a> by <a href="https://iconscout.com/contributors/mello" target="_blank">mello</a><br/>
<a href="https://iconscout.com/icons/emergency-siren" target="_blank">Free Emergency Siren  Icon</a> by <a href="https://iconscout.com/contributors/dinosoftlabs" target="_blank">Dinosoft Lab</a><br/>
<a href="https://iconscout.com/icons/teeth-bacteria" target="_blank">Free Teeth bacteria  Icon</a> by <a href="https://iconscout.com/contributors/iconscout">IconScout Store</a> on <a href="https://iconscout.com">IconScout</a> <br/>

<a href="https://iconscout.com/icons/health-consultation" target="_blank">Free Health Consultation  Icon</a> on <a href="https://iconscout.com">IconScout</a><br/>
<a href="https://iconscout.com/icons/search" target="_blank">Free Search  Icon</a> by <a href="https://iconscout.com/contributors/fauzanadiima" target="_blank">Kreasi Kanvas</a><br/>
<a href="https://iconscout.com/icons/tooth" target="_blank">Free Tooth  Icon</a> by <a href="https://iconscout.com/contributors/hatti27789">Matthias Hartmann</a> on <a href="https://iconscout.com">IconScout</a><br/>

    </>
        
    )
}

export default Attributions