import React, { useState } from 'react'
import { BsClock, BsPhone } from 'react-icons/bs'
import '../../../css/Dentallocation.css'
import img1 from '../../../images/dentalloc.jpg'
import Spcarousel from '../../Spcarousel'
import Faq from 'react-faq-component';
import { RiQuestionAnswerLine } from "react-icons/ri";
import Spforms from '../../Spform'
import Abovef from '../../Abovef'
import img2 from '../../../images/sp.webp'
import { Link } from 'react-router-dom'


const data = {

    rows: [
        {
            title: <h1 className="titleheading">Q. What are dental implants, and how do they work?</h1>,
            content: (
                <span className="Spancon">
                    Dental implants are artificial tooth roots made of biocompatible materials like titanium. They are surgically placed into the jawbone to provide a stable foundation for replacement teeth. Over time, the bone fuses with the implant, creating a strong and permanent anchor for dental prosthetics.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">Q. Are dental implants suitable for everyone?</h1>,
            content: (
                <span className="Spancon">
                    Dental implants are a versatile solution, but not everyone is an ideal candidate. Your suitability depends on factors such as bone density, overall health, and oral hygiene. A comprehensive evaluation by a dental implant specialist will determine your eligibility.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">Q. How long do dental implants last?</h1>,
            content: (
                <span className="Spancon">
                    Dental implants have a high success rate and can last a lifetime with proper care. Regular oral hygiene practices and routine dental check-ups are essential for maintaining the longevity of your dental implants.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">Q. Is the dental implant procedure painful?</h1>,
            content: (
                <span className="Spancon">
                    Most patients report minimal discomfort during the procedure, which is often performed under local anesthesia. Any post-operative discomfort can typically be managed with prescribed pain medication.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">Q. How long does the entire dental implant process take?</h1>,
            content: (
                <span className="Spancon">
                    The duration varies depending on individual factors and the complexity of the case. On average, it may take a few months from the implant placement surgery to the final placement of the crown or restoration.
                </span>
            ),
        },

        {
            title: <h1 className="titleheading">Q. What is the recovery process like after dental implant surgery?</h1>,
            content: (
                <span className="Spancon">
                    Recovery times vary but typically include a few days of rest and limited activity. You'll be advised to follow specific post-operative care instructions provided by our dental implant specialist.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">Q. Can smokers get dental implants?</h1>,
            content: (
                <span className="Spancon">
                    While smoking can impact the success of dental implants, it's not an absolute contraindication. However, quitting smoking or reducing tobacco use can significantly improve the chances of a successful outcome.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">Q. Are dental implants covered by insurance?</h1>,
            content: (
                <span className="Spancon">
                    Dental implant coverage varies by insurance plans. Some plans may cover a portion of the cost, while others may not cover them at all. It's essential to check with your insurance provider or consult with your dental implant specialist to understand your coverage options.
                </span>
            ),
        },
    ],

    // Use the 'faqs' array to display your FAQs in your preferred frontend framework or JavaScript environment.

};

const styles = {
    bgColor: 'white',
    titleTextSize: '3rem',
    rowTitleColor: "blue",
    rowContentColor: 'grey',
    arrowColor: "black",
    transitionDuration: "0.5s",
    timingFunc: "ease",
    rowContentColor: 'grey',
    rowContentPaddingTop: '4%'
};

const config = {
    animate: true,
};




const Dentallocation = () => {

    const [locationName, setLocationName] = useState(''); // Set the initial value to an empty string

    // Function to update the locationName state with the text from the h1 element
    const updateLocationName = () => {
        const spanElement = document.querySelector('.locationwrap span');
        if (spanElement) {
            setLocationName(spanElement.innerText);
        }
    };

    React.useEffect(() => {
        updateLocationName();
    }, []);





    return (
        <div>
            <header>
                <div className='locationhcolor'>
                    <div className='locationwrap'>
                        <h1>Dental Implants in <span>Gregory Hills</span></h1>
                    </div>
                    <div className='locationstrip'>
                        <div>
                            <BsClock></BsClock>9:00-19:00  |  Open on weekends  |  <BsPhone></BsPhone>  <a href="tel://02 4603 1277">02 4603 1277</a>
                        </div>

                    </div>
                </div>

            </header>

            <div className='dentallocationoffers'>
                <h1>Special Dental Implants Offer: Save 20% on Your New Smile!</h1>
                <p>Book your consultation today to take advantage of this limited-time offer and invest in your long-term oral health. Don't miss out on this opportunity for a brighter, healthier smile!</p>
                <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'>
                    <button
                    className='aboutbookbutton'
                    style={{
                        backgroundColor: '#644222',
                        border: '1px solid #000',
                        margin: '0px',
                        padding: '0px'  // Example border value (adjust to your needs)
                    }}
                    >
                    Book Now
                </button>
                    </a> 


            </div>


            <div className='emergencygridwrap'>
                <div className='emergencygrid'>
                    <div className='energencygridcon'>
                        <h1>Best Dental Implants in {locationName}</h1>
                        <h3>If you're searching for the best dental implants in {locationName}, you've come to the right place.</h3>
                        <p>Dental implants have revolutionized the field of dentistry, offering a permanent solution to missing teeth and restoring your smile to its full potential. {locationName} boasts a range of dental clinics and professionals specializing in implant dentistry, making it essential to choose the best option for your unique needs.</p>
                        <p>When considering the best dental implants in {locationName}, you should prioritize experience and expertise. Look for a dental implant specialist with a proven track record of successful implant procedures. Experience matters because it ensures that your treatment is in the hands of professionals who have honed their skills and techniques over time.</p>
                        <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'>
                             <button
                            className='aboutbookbutton'
                            data-aos='zoom-out'
                            >
                            Book Now
                        </button>
                            </a>


                    </div>
                    <div className='energencygridimg'>
                        <img src={img1}></img>
                    </div>
                </div>
            </div>


            <div className='spcc'>
                <div className='spc' data-aos='flip-up' >
                    <Spcarousel />
                </div>
            </div>



            <div className='emergencygridwrap'>
                <div className='emergencygrid'>
                    <div className='energencygridimg'>
                        <img src={img1}></img>
                    </div>
                    <div className='energencygridcon'>
                        <h1>Affordable Dental Implants in {locationName}</h1>
                        <p>When it comes to your oral health and smile restoration, affordability should never compromise quality. That's why we're proud to offer affordable dental implants in {locationName} without sacrificing excellence. Dental implants are a remarkable solution for replacing missing teeth, providing a permanent and natural-looking alternative that not only restores your smile but also enhances your overall oral health.</p>
                        <p>At our {locationName} dental implant clinic, we understand the importance of making dental implants accessible to everyone. We believe that everyone deserves the opportunity to regain their confidence and bite functionality. Our experienced implant specialists use state-of-the-art technology and top-tier materials to ensure the longevity and reliability of your dental implants.</p>
                        <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'>
                            <button className='aboutbookbutton' data-aos='zoom-out'>
                            Book Now
                        </button>
                        </a>

                    </div>
                </div>
            </div>





            <div className="servicecomp" >
                <h1 className="faqheading" >Discover Your Smile's Secrets</h1>
                <h1 className="faqheadingd" >Frequently Asked Questions 	&nbsp; <RiQuestionAnswerLine /> </h1>
                <Faq
                    data={data}
                    styles={styles}
                    config={config} />
            </div>

            <div className='dentallocbancol'>
                <div className='dentallocban'>
                    <div>

                        <h1>Affordable Dental Implants in {locationName}: Your Path to a Confident Smile</h1>
                    </div>
                    <div>
                        <Link to='/Dental-Implants' >
                            <button className='aboutbookbutton' data-aos='zoom-out'>
                                Know More
                            </button>
                        </Link>
                    </div>

                    <div>
                    <button className='aboutbookbutton' data-aos='zoom-out'>
                            <a style={{ color: 'white' }} href="tel://02 4603 1277">Call Now</a>
                        </button>
                    </div>

                </div>
            </div>

            <div className='dentallocbgwrap'>
                <div className='emergencygridwrap'>
                    <div className='emergencygrid'>
                        <div className='energencygridimg'>
                            <img src={img2}></img>
                        </div>
                        <div className='energencygridcon'>
                            <h1>Painless Dental Implants in {locationName}</h1>
                            <ul>
                                <li>
                                    <h3>Minimal Discomfort</h3>
                                    <p>Latest pain management techniques for your comfort.</p>
                                </li>
                                <li>
                                    <h3>Expert Care</h3>
                                    <p>Proven track record of successful dental implant surgeries.</p>
                                </li>
                                <li>
                                    <h3>Personalized Approach</h3>
                                    <p>Tailored treatment plans for a painless experience.</p>
                                </li>
                                <li>
                                    <h3>State-of-the-Art Facilities</h3>
                                    <p>Cutting-edge technology for precise implant placement.</p>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>

            </div>






            <div>
                <Spforms />
            </div>


            <div className='af'>

                <Abovef />
            </div>
        </div>
    )
}

export default Dentallocation