import React from 'react';
import '../css/Header.css';
import { BsClock, BsPhone } from 'react-icons/bs';
import { FaInstagram, FaWhatsapp, FaMailBulk } from 'react-icons/fa';

const Header = () => {


    return (
        <div>
            <div className='abovenav' >
                <div className='aboveitems'>
                    <div className='timings'>
                        <BsClock/> Mon-Fri  |  9:00-19:00  |  Open all 7 days |  <BsPhone/>  <a href="tel://02 4603 1277">02 4603 1277</a>
                    </div>
                    <div className='socialicons' >
                        
                        <a href="https://www.instagram.com/dr.ankitgoyal_/" target="_blank" rel="noopener noreferrer">
                            <FaInstagram className="socials"/>
                        </a>
                        <a href="https://wa.me/+61449968018?text=Hi%20Doctor" target="_blank" rel="noopener noreferrer">
                            <FaWhatsapp className="socials"/>
                        </a>
                        <a href="mailto:springfarmdental@gmail.com" target="_blank" rel="noopener noreferrer">
                            <FaMailBulk className="socials"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
