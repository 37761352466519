import React, { useState } from 'react'
import { BsClock, BsPhone } from 'react-icons/bs'
import '../../../css/Dentallocation.css'
import Faq from 'react-faq-component';
import { RiQuestionAnswerLine } from "react-icons/ri";
import Spforms from '../../Spform'
import Abovef from '../../Abovef'
import img1 from '../../../images/veneers5.webp'
import img3 from '../../../images/veneers3.webp'
import img2 from '../../../images/veneers1.webp'
import img6 from '../../../svg/health-consultation.svg'
import img7 from '../../../svg/search.svg'
import img8 from '../../../svg/tooth.svg'
import img9 from '../../../svg/braces-teeth.svg'

const data = {
  rows: [
    {
      title: <h1 className="titleheading">Q. What are dental veneers, and how do they work?</h1>,
      content: (
        <span className="Spancon">
          Dental veneers are thin, custom-made shells crafted from materials like porcelain or composite resin. They are bonded to the front surface of teeth to enhance their appearance. Veneers can correct issues like discoloration, chips, misalignment, or gaps, providing you with a beautiful and natural-looking smile.
        </span>
      ),
    },
    {
      title: <h1 className="titleheading">Q. Are veneers suitable for everyone?</h1>,
      content: (
        <span className="Spancon">
          Veneers are a versatile cosmetic dentistry solution, but not everyone may be a suitable candidate. Your eligibility will be determined by your dentist after a thorough evaluation of your oral health and specific dental issues.
        </span>
      ),
    },
    {
      title: <h1 className="titleheading">Q. How long do veneers last?</h1>,
      content: (
        <span className="Spancon">
          The longevity of veneers depends on factors such as oral hygiene and maintenance. With proper care, veneers can last for 10-15 years or more before they may need replacement.
        </span>
      ),
    },
    {
      title: <h1 className="titleheading">Q. Is the veneer placement procedure painful?</h1>,
      content: (
        <span className="Spancon">
          Veneer placement is typically a minimally invasive and relatively painless procedure. Your dentist may use local anesthesia to ensure your comfort during the process.
        </span>
      ),
    },
    {
      title: <h1 className="titleheading">Q. How are veneers different from crowns?</h1>,
      content: (
        <span className="Spancon">
          Veneers and crowns are both dental restorations, but they serve different purposes. Veneers primarily address cosmetic issues, such as stains and minor imperfections, by covering only the front surface of teeth. Crowns, on the other hand, are used to restore and protect damaged or weakened teeth by covering the entire tooth.
        </span>
      ),
    },
    {
      title: <h1 className="titleheading">Q. Can veneers be stained?</h1>,
      content: (
        <span className="Spancon">
          Porcelain veneers are highly resistant to staining, allowing you to maintain a bright and beautiful smile. However, it's essential to avoid excessive consumption of staining agents like coffee or red wine.
        </span>
      ),
    },
    
  ],
};

const styles = {
  bgColor: 'white',
  titleTextSize: '3rem',
  rowTitleColor: 'blue',
  rowContentColor: 'grey',
  arrowColor: 'black',
  transitionDuration: '0.5s',
  timingFunc: 'ease',
  rowContentColor: 'grey',
  rowContentPaddingTop: '4%',
};

const config = {
  animate: true,
};

const Dentalveneers = () => {



  const [locationName, setLocationName] = useState(''); // Set the initial value to an empty string

  // Function to update the locationName state with the text from the h1 element
  const updateLocationName = () => {
      const spanElement = document.querySelector('.locationwrap span');
      if (spanElement) {
          setLocationName(spanElement.innerText);
      }
  };

  React.useEffect(() => {
      updateLocationName();
  }, []);








  return (
    <div>
      <header>
        <div className='locationhcolor'>
          <div className='locationwrap'>
            <h1>Veneers Services in <span>Mount Annan</span></h1>
          </div>
          <div className='locationstrip'>
            <div>
              <BsClock></BsClock>9:00-19:00  |  Open on weekends  |  <BsPhone></BsPhone>  <a href="tel://02 4603 1277">02 4603 1277</a>
            </div>
          </div>
        </div>
      </header>

      <div className='dentallocationoffers'>
        <h1>Transform Your Smile with Veneers</h1>
        <p>Get the beautiful smile you've always wanted with our veneers services in {locationName}.</p>
      </div>

      <div className='emergencygridwrap'>
        <div className='emergencygrid'>
          <div className='energencygridimg'>
            <img src={img1} alt="Veneers Service"></img>
          </div>
          <div className='energencygridcon'>
            <h1>Discover the Beauty of Dental Veneers</h1>
            <h3>If you're looking for a radiant and flawless smile, our dental veneers can help you achieve it.</h3>
            <p>Dental veneers are a cosmetic dentistry solution designed to address a range of imperfections in your teeth. Whether you have discolored, misaligned, chipped, or gapped teeth, veneers can provide a natural-looking and durable solution.</p>
            <p>Our {locationName} dental clinic offers high-quality porcelain veneers that are custom-made to fit your teeth perfectly. Our experienced cosmetic dentists use the latest techniques to ensure that your veneers look and feel natural, giving you the confidence to show off your beautiful smile.</p>
            <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'> <button className='aboutbookbutton' data-aos='zoom-out'>Schedule Your Veneers Consultation</button></a>



          </div>
        </div>
      </div>

     



      <div className='veneerscontentcard'>
                <div className='roundcardscolor'>
                    <div className='roundcardswrap'>
                        <h1>Our Simple Four-Step Porcelain Veneers Process</h1>
                        <p>Transforming your smile into a stunning Hollywood look is as easy as 1, 2, 3, 4 with our porcelain veneers process. This straightforward process is completed in just four visits, all conveniently in-chair at our clinic in Sydney's Spring Farm.</p>
                        <div class="orthocontainer">
                            <div class="orthotext">
                                <p> <h4>1. Initial Consultation</h4>
                                    During your initial consultation, you'll meet with one of our friendly and experienced dentists. They will assess whether porcelain veneers are the ideal solution for your teeth. This includes a thorough examination, X-rays, photography, and a discussion of your goals and expectations.</p>
                            </div>
                            <div class="orthocircle">
                                <img src={img6} alt="Your Image" />
                            </div>
                        </div>
                        <div class="orthocontainer">
                            <div class="orthotext1">
                                <p><h4>2. Planning</h4>
                                    After receiving your approval, we'll create a treatment plan. This involves selecting the right shade of porcelain to match your natural teeth, determining the quantity of veneers needed, and planning their optimal placement.</p>
                            </div>
                            <div class="orthocircle1">
                                <img src={img7} alt="Your Image" />
                            </div>
                        </div>
                        <div class="orthocontainer">
                            <div class="orthotext">
                                <p><h4>3. Tooth Preparation</h4>
                                    Prior to veneer placement, your teeth will be prepared. This may entail the removal of a small amount of enamel from the front surface to accommodate the veneers. Sedatives can be administered for your comfort if requested. After preparation, a digital impression will be taken to create a mold of your teeth. This mold is sent to the dental lab for custom-made veneers. Meanwhile, you'll receive temporary veneers to protect your teeth.</p>
                            </div>
                            <div class="orthocircle">
                                <img src={img8} alt="Your Image" />
                            </div>
                        </div>
                        <div class="orthocontainer">
                            <div class="orthotext1">
                                <p><h4>4. Veneer Placement</h4>
                                    Once your permanent veneers are ready, you'll return to our clinic for placement. Our team will affix them to your teeth using specialized dental cement. We'll make any necessary adjustments to ensure a perfect fit and a natural appearance.</p>
                            </div>
                            <div class="orthocircle1">
                                <img src={img9} alt="Your Image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>







      <div className='emergencygridwrap'>
        <div className='emergencygrid'>
          
          <div className='energencygridcon'>
            <h1>Why Choose Our Veneers Services?</h1>
            <p>Our commitment to excellence sets us apart:</p>
            <ul>
              <li>
                <h3>Customized Veneers</h3>
                <p>We create veneers that are tailored to your unique smile, ensuring a perfect fit and natural appearance.</p>
              </li>
              <li>
                <h3>Experienced Cosmetic Dentists</h3>
                <p>Our skilled cosmetic dentists have a proven track record of delivering beautiful and long-lasting results.</p>
              </li>
              <li>
                <h3>Advanced Techniques</h3>
                <p>We utilize the latest techniques and materials to provide you with veneers that stand the test of time.</p>
              </li>
              <li>
                <h3>Personalized Care</h3>
                <p>Your journey to a stunning smile begins with a comprehensive consultation and treatment plan tailored to your needs.</p>
              </li>
            </ul>
            

<a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'> <button className='aboutbookbutton' data-aos='zoom-out'>Book Your Veneers Consultation</button></a>


          </div>
          <div className='energencygridimg'>
            <img src={img3} alt="Veneers Service"></img>
          </div>
        </div>
      </div>

      <div className="servicecomp">
        <h1 className="faqheading">Frequently Asked Questions</h1>
        <h1 className="faqheadingd">About Veneers <RiQuestionAnswerLine /></h1>
        <Faq
          data={data}
          styles={styles}
          config={config}
        />
      </div>

    

      <div className='dentallocbancol'>
                <div className='dentallocban'>
                    <div>

                        <h1>Affordable Veneers in {locationName}: Get The Smile You Deserve</h1>
                    </div>
                    <div>

                    <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'> <button className='aboutbookbutton' data-aos='zoom-out'>Book Now</button></a>

  
                    </div>

                    <div>

                    <a href="tel://02 4603 1277">
              <button className='aboutbookbutton' data-aos='zoom-out'>
                Call Now
              </button>
            </a>

                    </div>
                </div>
            </div>
      <div className='dentallocbgwrap1'>
        <div className='emergencygridwrap'>
          <div className='emergencygrid'>
            <div className='energencygridimg'>
              <img src={img2} alt="Veneers Service"></img>
            </div>
            <div className='energencygridcon'>
              <h1>Your Path to a Beautiful Smile</h1>
              <ul>
                <li>
                  <h3>Enhance Your Confidence</h3>
                  <p>Veneers can transform your smile and boost your self-esteem.</p>
                </li>
                <li>
                  <h3>Stain-Resistant</h3>
                  <p>Our porcelain veneers are highly resistant to staining, ensuring a long-lasting bright smile.</p>
                </li>
                <li>
                  <h3>Personalized Treatment</h3>
                  <p>We customize your veneers to match your unique preferences and facial features.</p>
                </li>
                <li>
                  <h3>Expert Craftsmanship</h3>
                  <p>Our skilled dental professionals craft veneers with precision and attention to detail.</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Spforms />
      </div>

      <div className='af'>
        <Abovef/>
      </div>
    </div>
  );
};

export default Dentalveneers;
