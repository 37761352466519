import React from 'react'
import '../css/Aboutpage.css'
import img3 from '../images/dentalhover1.webp';
import About from './About';
import Who from './Who';
import System from './System';
import Why from './Why';
import Book from './Book';

const Aboutpage = () => {
    return (
        <div>
            <div className='aboutheading'>
                <img className='hoverimg' src={img3} />
                <div className="aboutname">
                    <h1>About Us</h1>
                    <p>Your Trusted Dental Care Experts</p>
                </div>
            </div>

            <div className="name">
                <h1>About Us</h1>
                <p>Your Trusted Dental Care Experts</p>
            </div>
            <About/>
            <Who/>
            <System/>
            <Why/>
            <Book/>
        </div>
    )
}

export default Aboutpage