import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../css/Offerscard.css';

const OffersCard = ({ heading, price, description, subheading }) => {
  useEffect(() => {
    AOS.init({ duration: 1000,
      once: true, // Animations will only occur once
      mirror: false, // Animation will not be mirrored when scrolling down
    }); // Initialize AOS with a default duration of 1000ms
  }, []);

  return (
    <div className='offerscard' data-aos='fade-up'>
      <div className='offerheading' data-aos='fade-right'>
        <h1>{heading}</h1>
        <div className='offersubheading'data-aos='fade-left'>
          <h2>{subheading}</h2>
        </div>
        <div className='offerprice'data-aos='fade-right'>
          <span>{price}</span>
        </div>
        <div className='offerdes'data-aos='fade-left'>
          <ul>
            {description.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
        <div className='offerbook'>
        <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'> <button className='bookzbutton' data-aos='fade-up'>Book Now</button></a>

        </div>
      </div>
    </div>
  );
};

export default OffersCard;
