import React from 'react'
import '../css/Contactcard1.css'
import img1 from '../gifs/customer-service-chat.gif'
import img2 from '../gifs/female-call-center-operator.gif'

const Contactcard1 = () => {
    return (
        <>
            <div className='cc1grid'>
                <a href="tel://+61 449 968 018">
                    <button>
                        <div className='cc1'>
                            <img src={img2} alt="Customer Support" />
                            <h1>Get Support</h1>
                            <p>If you have any questions or need assistance, our dedicated support team is here to provide you with the best service and guidance.</p>
                        </div>
                    </button>
                </a>

                <a href='#sfdforms'>
                    <button>
                        <div className='cc1'>
                            <img src={img1} alt="Ask Questions" />
                            <h1>Ask Questions</h1>
                            <p>If you have any inquiries or need help, feel free to ask. We're here to provide answers and support to ensure your needs are met.</p>
                        </div>
                    </button>
                </a>
            </div>


        </>
    )
}

export default Contactcard1