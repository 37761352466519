import React from 'react';
import '../css/Contentcard.css'
import { Link } from 'react-router-dom'

const ContentCard = ({ imgSrc, imgSrc1, serviceName, description, link, link2 }) => {
    return (
        <div className='contentcard'>
            <div className='insidecard' style={{ backgroundImage: `url(${imgSrc1})`, backgroundSize: 'cover', backgroundSize: '130px', backgroundRepeat: 'no-repeat', backgroundPosition: 'top right' }}>
                <img className='serviceimg' src={imgSrc} alt='Service Image' />
                <h1 className='servicename'>{serviceName}</h1>
                <p>{description}</p>
                <br />
                <Link className='links' to={link}>Read more →</Link>
                <a
                    className='links'
                    style={{
                        backgroundColor: '#644222',
                        color: 'white',
                        border: '2.3px solid blanchedalmond',
                        padding: '3.2px',
                        position: 'absolute',
                        bottom: '10px',
                        right: '10px',
                        textDecoration: 'none' // Optionally remove underline from the link
                    }}
                    href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'
                   
                    rel='noopener noreferrer' // Recommended for security reasons when using target='_blank'
                >
                    BOOK NOW
                </a>

            </div>
        </div>
    );
};

export default ContentCard;
