import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/Navbar.css';
import { FaBars } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';
import img1 from '../images/logo.png';
import { BsPhone } from 'react-icons/bs';

const Navbar = () => {
    const [Mobile, setMobile] = useState(false);
    const [color, setColor] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const changeColor = () => {
        if (window.scrollY >= 90) {
            setColor(true);
        } else {
            setColor(false);
        }
    };

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('scroll', changeColor);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', changeColor);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <nav className={color ? 'navbar navbar-bg' : 'navbar'}>
                <div className='navwrap'>
                    <div className='navcontainer'>
                        <Link to='/' className='link-style'>
                            <h3 className='logo'><img src={img1} style={{ height: '70px' }} alt="Description" /></h3>
                        </Link>
                        <ul className={Mobile ? 'nav-links-mobile' : 'nav-links'} onClick={() => setMobile(false)}>
                            <Link to='/'> <li>Home</li> </Link>
                            <Link to='/Aboutpage'> <li>About</li> </Link>
                            <Link to='/Contactpage'> <li>Contact</li> </Link>
                            <Link to="/Dentalservice"><li>Services</li> </Link>
                            <Link to="/Locations"><li>Locations</li> </Link>
                            <Link to='/Pricing'> <li>Pricing</li> </Link>
                        </ul>
                        <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'>
                            <button className='bookbutton' data-aos='fade-up'>Book Now</button>
                        </a>
                        <button className='mobile-menu-icon' onClick={() => setMobile(!Mobile)}>
                            {Mobile ? <ImCross /> : <FaBars />}
                        </button>
                        {windowWidth < 770 && (
                            <span style={{ fontSize: '0.8rem', marginRight: '15%', color: '#644222', fontWeight: '900' }}>
                                <BsPhone />
                                <a href="tel://02 4603 1277" style={{ color: '#644222' }}>02 4603 1277</a>
                            </span>
                        )}
                    </div>
                </div>
            </nav>
            <div className='zz'>
                {windowWidth < 768 && (
                    <p className='zzp'>
                        Open all 7 days of the week
                        <br />
                        <strong>(Free Parking)</strong>
                    </p>
                )}
                {windowWidth < 768 && (
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                            <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'>
                                <button className='bookzzbutton' style={{ width: '150px', display: 'flex', justifyContent: 'center' }} data-aos='fade-up'>
                                    Book Now
                                </button>
                            </a>
                            <a href="tel://02 4603 1277">
                                <button style={{ marginLeft: '14px', width: '150px', display: 'flex', justifyContent: 'center' }} className='bookzzbutton' data-aos='fade-up'>
                                    Call Now
                                </button>
                            </a>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default Navbar;