import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../css/Paymentplan.css';
import { saveAs } from 'file-saver';
import pdfFile from '../components/oop.pdf';



const PaymentPlan = () => {
  useEffect(() => {
    AOS.init({ duration: 2000,
      once: true, // Animations will only occur once
      mirror: false, // Animation will not be mirrored when scrolling down
    }); // Initialize AOS with a default duration of 1000ms
  }, []);



    // const handleDownload = () => {

    //   saveAs(pdfFile, 'my-document.pdf');
    // };

  return (
    <div className='payment-plan-container'>
      <div className='paymentsec' data-aos='fade-right'>
        <div className='paymentsec1'>
          <h4>Pricing & Payment Plans</h4>
        </div>
        <div className='paymentsec2'>
          <p>Our price list is a guide only, please book a consultation for a full quote.</p>
          <br /><br /><br />
          <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'>
          <button className='button-28'>Get Price List</button>
          </a>
        </div>
      </div>
      <div className='paymentsec' data-aos='fade-left'>
        <div className='paymentsec3'>
          <h4>Complimentary Discovery Session</h4>
          <br /><br /><br />
          <p>New patient consultations are complimentary and booked for Monday to Friday only.
            Pricing and fees are subject to change at any time, without any given notice. See terms and conditions for further information. Prices as of February 2023.
          </p>
        </div>
        <div className='paymentsec4'>
          <h4>Includes</h4>
          <br />
          <ul>
            <li>Comprehensive dental examination & explanation of findings</li><br />
            <li>Soft tissue and gum check</li><br />
            <li>Oral cancer check</li><br />
            <li>Bite analysis</li><br />
            <li>A series of dental photographs</li><br />
            <li>A discussion about your dental concerns, goals & desires</li><br />
            <li>A personalized treatment plan</li><br />
          </ul>
          <br /><br /><br />
        </div>

      </div>
    </div>
  );
}

export default PaymentPlan;
