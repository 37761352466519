import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../css/About.css';
import img3 from '../images/dentalhover1.webp';
import img4 from '../images/abtimg5.webp';
import { Link } from 'react-router-dom';

const About = () => {
  useEffect(() => {
    AOS.init({ duration: 2000, 
        once: true, // Animations will only occur once
        mirror: false, // Animation will not be mirrored when scrolling down
    }); // Initialize AOS with a default duration of 1000ms
  }, []);

  return (
    <div className='aboutwrap'>
      <img className='hoverimg' data-aos='fade-up' src={img3} />
      <div className='aboutcontainer' data-aos='fade-right'>
        <img className='img1' src={img4} />
      </div>
      <div className='aboutcontent' data-aos='fade-left'>
        <span className='smallheading' style={{ color: '#644222', fontWeight: '1000', fontSize:'2rem'}}>
          About Us
        </span>
        <span className='mainheading' data-aos='fade-up'>
          <h2>Spring Farm Dental, Spring Farm, NSW</h2>
          <br />
        </span>
        <span className='contentz' data-aos='fade-up'>
        <p>
            Having been the face of South-West Sydney Spring Farm, Mount Annan, Elderslie, Camden, Narellan, Oran Park and Edmondson Park - we are renowned in cosmetic and implant dentistry - having designed and completed numerous smiles.
        </p>
        <p>
            We are South-West Sydney's choice for obtaining the smiles they desire that personifies expression of freedom - A pearl of dentistry.
        </p>
        <p>
       <strong> Dr. Ankit Goyal </strong>is the principal dentist. He lives in the local area and understand the intricacies of the community.
        </p>
        <p>
            Being highly qualified and having worked with world-renowned names, our team desires to bring that essence to your hometown of South-West Sydney.
        </p>
        <p>
            We aim to become friends of the community, of the families who want a secure place to feel confident about their smile, not being judged, of the people who had to neglect their teeth for years due to hardships of life. We are one of you.
        </p>
        <p>
            "By the community for the community"
        </p>
        </span>
        <ul className='aboutlist' data-aos='fade-up'>
          <li className='abtlist'>Your Local Cosmetic Dentistry Experts.</li>
          <li className='abtlist'>A Pearl of Dentistry.</li>
          <li className='abtlist'>World-Class Expertise, Hometown Care.</li>
          <li className='abtlist'>For All Who Seek Confident Smiles.</li>
        </ul>
        <div className='aboutbutton'>


         <Link to='/Aboutpage'>
            <button className='aboutbookbutton' data-aos='zoom-out'>
            Discover More
          </button>
          </Link>
          {/* <span className='signature' data-aos='fade-up'>
            Ankit
          </span> */}
        </div>
      </div>
    </div>
  );
};

export default About;
