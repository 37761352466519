import React from "react";
import Faq from 'react-faq-component';
import '../css/Servicecomponent.css'
import { RiQuestionAnswerLine } from "react-icons/ri";
const data = {

  rows:[
    {
      title: <h1 className="titleheading">Q. Why is regular teeth cleaning important?</h1>,
      content: (
        <span className="Spancon">
          Regular teeth cleaning is essential because it helps remove plaque and tartar buildup, which can lead to tooth decay and gum disease. Professional dental cleanings also help maintain fresh breath and a bright smile, contributing to overall oral health.
        </span>
      ),
    },
    {
      title: <h1 className="titleheading">Q. How often should I have my teeth professionally cleaned?</h1>,
      content: (
        <span className="Spancon">
          Most dentists recommend getting your teeth professionally cleaned every six months. However, the frequency may vary based on your oral health needs. Your dentist will determine the ideal schedule for your cleanings during your checkup.
        </span>
      ),
    },
    {
      title: <h1 className="titleheading">Q. What happens during an oral checkup?</h1>,
      content: (
        <span className="Spancon">
          During an oral checkup, your dentist will perform a thorough examination of your teeth, gums, and overall oral health. They may also take X-rays to detect any underlying issues not visible to the naked eye. Your dentist will discuss any findings with you and create a personalized treatment plan if necessary.
        </span>
      ),
    },
    {
      title: <h1 className="titleheading">Q. Does teeth cleaning hurt?</h1>,
      content: (
        <span className="Spancon">
          Teeth cleaning is generally not painful. Dental hygienists use special instruments to remove plaque and tartar from your teeth and gums, and they are trained to ensure your comfort during the process. Some individuals may experience mild sensitivity or discomfort, but it is typically minimal and temporary.
        </span>
      ),
    },
    {
      title: <h1 className="titleheading">Q. Can an oral checkup detect oral health issues early?</h1>,
      content: (
        <span className="Spancon">
          Yes, oral checkups are essential for detecting oral health issues at an early stage. Dentists can identify problems such as cavities, gum disease, oral cancer, and other conditions in their early and more treatable phases. Regular checkups can help prevent the progression of these issues.
        </span>
      ),
    },
  ],
  
  
};

const styles = {
  bgColor: 'white',
  titleTextSize: '3rem',
  rowTitleColor: "blue",
  rowContentColor: 'grey',
  arrowColor: "black",
  transitionDuration: "0.5s",
  timingFunc: "ease",
  rowContentColor: 'grey',
  rowContentPaddingTop: '4%'
};

const config = {
  animate: true,
};


const Checkupandcleanques = () => {
  return (
  <>
    <div className="servicecomp" >
      <h1 className="faqheading" >Discover Your Smile's Secrets</h1>
      <h1 className="faqheadingd" >Frequently Asked Questions 	&nbsp; <RiQuestionAnswerLine /> </h1>
      <Faq
        data={data}
        styles={styles}
        config={config} />
    </div>
  </>
  );
};

export default Checkupandcleanques;
