import React from 'react'
import img1 from '../images/ortho1.webp'
import img2 from '../images/ortho.webp'
import img3 from '../images/ortho2.webp'
import '../css/Orthoandbraces.css'
import Faq from 'react-faq-component';
import '../css/Servicecomponent.css'
import { RiQuestionAnswerLine } from "react-icons/ri";
import Book from './Book'
import Spforms from './Spform'
import Serviceop from './Serviceop'
import img6 from '../svg/health-consultation.svg'
import img7 from '../svg/search.svg'
import img8 from '../svg/tooth.svg'
import img9 from '../svg/braces-teeth.svg'
import img10 from '../svg/girlsmilesvg.svg'


const data = {

    rows: [
        {
            title: <h1 className="titleheading">Q. What are teeth aligners, and how do they work?</h1>,
            content: (
                <span className="Spancon">
                    Teeth aligners are orthodontic devices designed to straighten misaligned teeth. They work by applying gentle, consistent pressure on the teeth to gradually move them into their desired position. Unlike traditional braces, aligners are clear, removable, and virtually invisible.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">Q. How long does the teeth aligner treatment typically take?</h1>,
            content: (
                <span className="Spancon">
                    The duration of teeth aligner treatment varies depending on the complexity of your dental issues. In general, it can take anywhere from several months to a few years. Your orthodontist will create a personalized treatment plan to estimate the time required for your specific case.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">Q. Are teeth aligners painful to wear?</h1>,
            content: (
                <span className="Spancon">
                    Most people do not find teeth aligners to be painful, but they may experience some discomfort or pressure during the initial days of wearing a new set of aligners. This discomfort is usually temporary and can be managed with over-the-counter pain relievers. Your mouth may also get used to the aligners over time.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">Q. Can I eat and drink normally while wearing teeth aligners?</h1>,
            content: (
                <span className="Spancon">
                    It's recommended to remove your aligners when eating or drinking anything other than water. This helps prevent staining and damage to the aligners. Afterward, you should brush your teeth before reinserting the aligners to maintain proper oral hygiene.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">Q. How often should I see my orthodontist during teeth aligner treatment?</h1>,
            content: (
                <span className="Spancon">
                    Your orthodontist will schedule regular check-up appointments, typically every 4-6 weeks, to monitor your progress and provide you with new sets of aligners. These appointments are crucial for ensuring the treatment is proceeding as planned and making any necessary adjustments to your treatment plan.
                </span>
            ),
        },
    ],

    // Use the 'faqs' array to display your FAQs in your preferred frontend framework or JavaScript environment.

};

const styles = {
    bgColor: 'white',
    titleTextSize: '3rem',
    rowTitleColor: "blue",
    rowContentColor: 'grey',
    arrowColor: "black",
    transitionDuration: "0.5s",
    timingFunc: "ease",
    rowContentColor: 'grey',
    rowContentPaddingTop: '4%'
};

const config = {
    animate: true,
};

const Orthoandbraces = () => {
    const buttonStyle = {
      textAlign: 'center',
      display: 'block',
      margin: '5% auto'
    };
  
    return (
      <>






        <div className='spbanner'>
          <img className='spimg' src={img1} alt="Image 1" />
        </div>
        <div className='spbbwrap'>
          <div className='spbbottom' data-aos='fade-up'>
            <span>Home {'>'} Services {'>'} Dental Implants </span>
            <h1>Unleash the Power of Your Smile</h1>
            <p>Your smile is your most potent accessory, yet many of us feel dissatisfied with the alignment and appearance of our teeth. At Spring Farm Dental, we present an array of solutions to help you attain the smile you've always desired. In addition to conventional and ceramic braces, we proudly offer Invisalign treatment.
            We extend a warm invitation to you to visit our Spring Farm clinic in Sydney. Here, our proficient dentists will carefully evaluate your teeth, mouth, and jaw to identify the optimal treatment path for you. Take the first step towards your journey to a flawless smile and schedule a consultation today.</p>
          </div>
        </div>

        <>
        <div className='indiofferrwrap'>
            <div className='indiofferwrap'>
                <div className='indiofferwrap1'>

                    <h1>SPECIAL OFFER</h1>
                    <div className='indigrid'>
                        <div className='indiofferimg'>
                            <img src={img10} ></img>
                        </div>
                        <div className='indioffercon'>
                            <h2>Book Today
                                <br/>
                            &
                                <br/>
                            Claim Your
                                <br/>
                                Free Consultation
                                <br/>
                                &
                                <br/>
                                $500 off 
                                <br/>
                            (For all Health Fund Holders)</h2>

                            <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'> <button className='aboutbookbutton' >Book Now</button></a>
                            <h4>
                            We Accept all Types of Health Funds
                            </h4>
                            <br/>
                        
                           
                        </div>
                    

                    </div>

                </div>
                </div>
            </div>
        </>



        <div className='orthocolor'>
          <div className='orthowrap'>
            <div className='orthogrid'>
              <div className='orthocontent' >
                <h1>Unleash the Power of Your Smile</h1>
                <p>Your smile is your most potent accessory, yet many of us feel dissatisfied with the alignment and appearance of our teeth. At Spring Farm Dental, we present an array of solutions to help you attain the smile you've always desired. In addition to conventional and ceramic braces, we proudly offer Invisalign treatment.
                We extend a warm invitation to you to visit our Spring Farm clinic in Sydney. Here, our proficient dentists will carefully evaluate your teeth, mouth, and jaw to identify the optimal treatment path for you. Take the first step towards your journey to a flawless smile and schedule a consultation today.</p>

                <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'> <button className='bookbutton' style={buttonStyle} >Book Now</button></a>
              </div>
              <div className='orthoimg' >
                <img src={img2} alt="Image 2" />
              </div>
            </div>
          </div>
        </div>
        <div className='braces'>
          <div className='braceswrap'>
            <div className='orthogrid'>
              <div className='orthoimg'>
                <img src={img3} alt="Image 3" />
              </div>
              <div className='bracescontent'>
                <h1>Invisalign vs. Braces: Which Is Right for You?</h1>
                <p>Both Invisalign and traditional braces can effectively address various oral and aesthetic health concerns, including:</p>
                <ul>
                  <li>• Crowded Teeth</li>
                  <li>• Crossbite</li>
                  <li>• Gaps in Teeth</li>
                  <li>• Misaligned Teeth</li>
                  <li>• Tooth Shape</li>
                  <li>• Open Bite</li>
                  <li>• Overbite</li>
                  <li>• Underbite</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='extrainfowrap'>
          <div className='extrainfo'>
            <div className='extrainfocon'>
              <h1>Invisalign vs. Braces: Making the Right Choice</h1>
              <p>Both Invisalign and traditional braces have their unique applications, and the choice between them is not a matter of superiority. You might be pondering, "Is Invisalign better than braces?" There's no definitive answer, as it depends on your individual preferences and needs.
              If you're concerned about maintaining the discipline of wearing Invisalign aligners for up to 22 hours daily, traditional braces could be the more suitable choice for you. On the other hand, if you value discretion and a less noticeable treatment, Invisalign might be your preference.
              Our friendly dentists are here to assist you in evaluating your options during your consultation.
              </p>
            </div>
          </div>
        </div>
        <div className='checkeligible'>
          <div className='checkeligiblewrap'>
            <div className='checkeligiblegrid'>
              <div className='eligibecon'>
                <h1>Is Invisalign Right for You?</h1>
                <p>Invisalign is a popular treatment for enhancing smiles, but it may not be suitable for everyone. Before considering Invisalign in Spring Farm, certain factors need to be evaluated:</p>
                <ul>
                  <li>If you have gum disease, cavities, or require oral surgery, these issues must be addressed before you can be considered a candidate for Invisalign.</li>
                  <li>If you have dental implants, bridges, or suffer from TMJ disorder, Invisalign may not be the most practical option. However, individual cases vary, and our Invisalign dentists can provide personalized insights to determine if it can work for you.</li>
                </ul>
                <p>For a comprehensive evaluation and personalized guidance, we encourage you to speak with our Invisalign dentists.</p>
              </div>
              <div className='eligibleimg'>
                <img src={img1} alt="Image 1" />
              </div>
            </div>
          </div>
        </div>
        <div className='roundcardscolor'>
          <div className='roundcardswrap'>
            <h1>Why Choose Invisalign Over Traditional Braces?</h1>
            <p>Invisalign offers several advantages that make it a more convenient alternative to traditional braces:</p>
            <div class="orthocontainer">
              <div class="orthotext">
                <p><strong>Appearance:</strong> Invisalign's clear aligners are nearly invisible, making them a preferred choice for those who wish to straighten their teeth discreetly. This option allows you to maintain a more natural appearance while undergoing orthodontic treatment, as opposed to the noticeable metal components of traditional braces.</p>
              </div>
              <div class="orthocircle">
                <img src={img6} alt="Your Image" />
              </div>
            </div>
            <div class="orthocontainer">
              <div class="orthotext1">
                <p><strong>Comfort:</strong> Invisalign aligners are crafted from smooth, comfortable plastic, eliminating the discomfort and mouth sores often associated with traditional braces. Their absence of sharp wires and brackets means a more pleasant experience throughout your treatment.</p>
              </div>
              <div class="orthocircle1">
                <img src={img7} alt="Your Image" />
              </div>
            </div>
            <div class="orthocontainer">
              <div class="orthotext">
                <p><strong>Removability:</strong> Invisalign aligners can be easily removed when necessary, such as for eating, brushing, or special occasions. This flexibility simplifies maintaining proper oral hygiene and enjoying unrestricted food choices, which can be challenging with traditional braces that are permanently fixed.</p>
              </div>
              <div class="orthocircle">
                <img src={img8} alt="Your Image" />
              </div>
            </div>
            <div class="orthocontainer">
              <div class="orthotext1">
                <p><strong>Easier Maintenance:</strong> Cleaning Invisalign aligners is uncomplicated. You can simply remove them for regular brushing and flossing, just like you would without orthodontic treatment. In contrast, traditional braces require special tools and techniques for effective cleaning, as food particles often become trapped in the wires and brackets.</p>
              </div>
              <div class="orthocircle1">
                <img src={img9} alt="Your Image" />
              </div>
            </div>
          </div>
        </div>
        <div className="servicecomp" >
          <h1 className="faqheading" >Discover Your Smile's Secrets</h1>
          <h1 className="faqheadingd" >Frequently Asked Questions 	&nbsp; <RiQuestionAnswerLine /> </h1>
          <Faq
            data={data}
            styles={styles}
            config={config} />
        </div>
        <div className='dentalgrid'>
          <div>
            <Serviceop />
          </div>
          <div>
            <Spforms />
          </div>
        </div>
        <Book />
      </>
    )
  }
  
  export default Orthoandbraces;