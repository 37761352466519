import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../css/Spoffers.css';

const Checkupandcleancost = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: false, // Animations will only occur once
            mirror: true, // Animation will not be mirrored when scrolling down
        }); // Initialize AOS with a default duration of 1000ms
    }, []);

    return (
        <div className='offerr'>
            <div className='offerswrap'>
                <div className='offersgrid'>
                    <span className='bestprices'>Best and Afordable</span>

                    <h1>Is a Deep Clean Necessary?</h1>
                    <p>If you're experiencing symptoms like red, swollen, or bleeding gums, persistent bad breath, or an unpleasant taste in your mouth, it could be the early stages of periodontal disease. To prevent it from progressing to periodontitis, our dentist or dental hygienist may recommend a deep clean.</p>
                    <p>A deep clean, also known as scaling and root planing, is a non-invasive dental procedure designed to eliminate stubborn plaque and bacteria that have infiltrated beneath your gum line, affecting the underlying bone. Undergoing a deep clean is crucial for maintaining optimal oral health and preventing the advancement of gum disease.</p>
                    <br />
                    <p>Benefits of a Deep Clean:</p>
                    <ul>
                        <li>A cleaner and healthier feeling mouth.</li>
                        <li>Smoother teeth surfaces.</li>
                        <li>Increased chances of preserving your adult teeth.</li>
                    </ul>
                    <br />
                    <p>During a deep clean, our dental professional employs specialized tools to remove plaque and tartar buildup from your tooth roots and surfaces. Smoothing the root surfaces helps prevent bacteria from adhering and promotes gum healing.</p>
                    <p>Don't let gum infection take its toll. Seek immediate care at our clinic, where our dentists can assess your gum inflammation or gum disease and provide appropriate treatment. Your oral health is our priority.</p>



                </div>
            </div>
        </div>
    );
};

export default Checkupandcleancost;
