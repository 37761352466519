import React, { useState } from 'react'
import '../css/Homebottom.css'
import CountUp from 'react-countup'
import ScrollTrigger from 'react-scroll-trigger'

const Homebottom = () => {
    const [counterOn, setCounterOn] = useState(false);

    return (
        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
            <div className='bottomcontainer'>
                <div className='countsgrid'>
                    <div className='countscontainer1'>
                        <h3>
                            {counterOn && <CountUp start={0} end={100} duration={5} delay={0} />}+ <br /> Braces
                        </h3>
                    </div>
                    <div className='countscontainer2'>
                        <h3>
                            {counterOn && <CountUp start={0} end={100} duration={5} delay={0} />}+ <br /> Invisalign
                        </h3>
                    </div>
                    <div className='countscontainer3'>
                        <h3>
                            {counterOn && <CountUp start={0} end={1000} duration={5} delay={0} />}+ <br /> Implants
                        </h3>
                    </div>
                        
                </div>

            </div>
        </ScrollTrigger>
    )
}

export default Homebottom