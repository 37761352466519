import React, { useEffect } from 'react';
import '../css/Abovef.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Abovef = () => {
  useEffect(() => {
    AOS.init({ duration: 1000,
      once: false, // Animations will only occur once
      mirror: true, // Animation will not be mirrored when scrolling down
    }); // Initialize aos with a default duration of 1000ms (1 second)
  }, []);

  return (
    <div className='abovef'>
      <h1 data-aos='fade-up'>Getting an appointment at Spring Farm Dental Clinic is fast and simple: book online now!</h1>
      <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'> <button className='aboutbookbutton' data-aos='fade-up'>Book Now</button></a>
    </div>
  );
};

export default Abovef;
