import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../css/Tabs.css';
import img1 from '../images/Frame5.webp';
import img2 from '../images/Frame6.webp';
import img3 from '../images/Frame7.webp';
import img4 from '../images/Frame8.webp';

function Tabs() {
    useEffect(() => {
        AOS.init({ duration: 1000,
            once: false, // Animations will only occur once
            mirror: true, // Animation will not be mirrored when scrolling down
        }); // Initialize AOS with a default duration of 1000ms
    }, []);

    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    return (
        <div>
            <div className="tabshead" data-aos='fade-up'>
                <h1>Spring Farm Dental</h1>
                <p>The Pearl of Dentistry.</p>
            </div>
            <div className="tabscontainer">
                <div className="bloc-tabs" data-aos='fade-up'>
                    <button
                        className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleTab(1)}
                    >
                        INNOVATIVE
                    </button>
                    <button
                        className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleTab(2)}
                    >
                        PATIENT-CENTERED
                    </button>
                    <button
                        className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleTab(3)}
                    >
                        SUPPORTIVE CLINIC
                    </button>
                    <button
                        className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleTab(4)}
                    >
                        YOUR FINANCIAL COMFORT
                    </button>
                </div>

                <div className="content-tabs">
                    <div
                        className={toggleState === 1 ? "content  active-content" : "content"}
                        data-aos={toggleState === 1 } // Use different animations for showing and hiding content
                    >
                        <div className="tabsimg">
                            <img src={img1} alt="Tab 1" />
                        </div>
                        <div className="tabsp">
                            <p className="mainheading">
                                Innovative
                            </p>
                            Your smile deserves the best, and that's what we deliver. We're not just a dental clinic, we're your gateway to the future of oral healthcare. Welcome to the next level of dentistry.
                        </div>
                    </div>
                    <div
                        className={toggleState === 2 ? "content  active-content" : "content"}
                        data-aos={toggleState === 2 }
                    >
                        <div className="tabsimg">
                            <img src={img2} alt="Tab 2" />
                        </div>
                        <div className="tabsp">
                            <p className="mainheading">
                                Patient<br />Centered
                            </p>
                            Every aspect of our practice is designed with your comfort and care in mind. We prioritize your individual needs and concerns, tailoring treatments and appointments to ensure a stress-free experience. 
                        </div>
                    </div>

                    <div
                        className={toggleState === 3 ? "content  active-content" : "content"}
                        data-aos={toggleState === 3 }
                    >
                        <div className="tabsimg">
                            <img src={img3} alt="Tab 3" />
                        </div>
                        <div className="tabsp">
                            <p className="mainheading">
                               Supportive Clinic
                            </p>
                            Our dedicated team of skilled professionals is prepared to address urgent concerns or provide late-night support, making sure you're never alone in your time of need.
                        </div>
                    </div>
                    <div
                        className={toggleState === 4 ? "content  active-content" : "content"}
                        data-aos={toggleState === 4 }
                    >
                        <div className="tabsimg">
                            <img src={img4} alt="Tab 4" />
                        </div>
                        <div className="tabsp">
                            <p className="mainheading">
                               Your Financial Comfort
                            </p>
                            We believe that everyone deserves a beautiful, healthy smile, and financial concerns should never stand in the way. With our flexible payment options, you can get the treatment you need without delay.
                        </div>
                    </div>
                </div>
            </div>
            <div className="tabsbottom" data-aos='fade-up'>
            </div>
        </div>
    );
}

export default Tabs;
