import React, { useState } from 'react';
import '../css/Serviceop.css'; // Import your CSS file if needed
import { Link } from 'react-router-dom';

const Serviceop = () => {
    const [isClosed, setIsClosed] = useState(true);
    const [isClosed1, setIsClosed1] = useState(true);
   
   
    const toggleDropdown = (event) => {
        event.preventDefault(); // Prevent the default anchor behavior
        setIsClosed(!isClosed);
    };
    
    const toggleDropdown1 = (event) => {
        event.preventDefault(); // Prevent the default anchor behavior
        setIsClosed1(!isClosed1);
    };


    return (
        <>
            <div>
                <nav className='serviceop'>
                    <div className='spotherservices'>
                        <h1>Care Services</h1>
                        <br />
                        <p>__________</p>
                        <br />
                    </div>
                    <ul className={`drop-down ${isClosed ? 'closed' : ''}`}>
                        <li>
                            <a href="#" className="nav-button" onClick={toggleDropdown}>
                                General Dentistry
                            </a>
                        </li>
                        <li>
                            <Link className="nav-button" to='/Checkupandclean'> <a>Check Up and Clean</a> </Link>
                        </li>
                        <li>
                            <Link className="nav-button" to='/Generaldentistry'> <a>Fillings</a> </Link>
                        </li>
                        <li>
                            <Link className="nav-button" to='/Generaldentistry'><a>Root Canal Treatment</a></Link>
                        </li>
                        <li>
                            <Link className="nav-button" to='/Generaldentistry'> <a>Crowns And Bridges</a></Link>
                        </li>
                        <li>
                            <Link className="nav-button" to='/Generaldentistry'><a>Wisdom Teeth</a></Link>
                        </li>
                        <li>
                            <Link className="nav-button" to='/Generaldentistry'> <a>Children's Dentistry</a></Link>
                        </li>
                    </ul>
                    <ul className={`drop-down1 ${isClosed1 ? 'closed' : ''}`}>
                        <li>
                            <a href="#" className="nav-button" onClick={toggleDropdown1}>
                                Coesmetic Dentistry
                            </a>
                        </li>
                        <li>
                            <Link className="nav-button" to='/Veneers'> <a>Veneers</a> </Link>
                        </li>
                        <li>
                            <Link className="nav-button" to='/Teethwhite'> <a>Teeth Whitening</a> </Link>
                        </li>


                    </ul>

                    <ul className={`drop-down-no`}>
                        <li>
                            <Link to='/Orthodontics-Invisalign'>
                                <a className="nav-button" >
                                    Orthodontics/Invisalign
                                </a>
                            </Link>
                        </li>
                    </ul>
                    <ul className={`drop-down-no`}>
                        <li>
                            <Link to='/Dental-Implants'>
                                <a className="nav-button" >
                                    Dental Implants
                                </a>
                            </Link>
                        </li>
                    </ul>

                    <ul className={`drop-down-no`}>
                        <li>

                            <Link to='/Emergency-Dentistry'> <a className="nav-button" >
                                Emergency Dentistry
                            </a> </Link>

                        </li>
                    </ul>

                </nav>
            </div>
        </>
    );
};

export default Serviceop;
