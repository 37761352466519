import React, { useState } from 'react';
import { Button, Form, Radio } from 'semantic-ui-react';
import axios from 'axios';
import 'aos/dist/aos.css';
import '../css/Form.css';
import { format } from 'date-fns';

function Spforms() {
  const [name, setName] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('+61');
  const [query, setQuery] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();

    const timestamp = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

    const objt = {
      name,
      age,
      gender,
      email,
      phone,
      query,
      timestamp,
    };

    axios
      .post(
        'https://sheetdb.io/api/v1/lyddo0o4lj3db',
        objt
      )
      .then((response) => {
        console.log(response);
        window.location.reload();
      });
  };

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);

    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValid = emailPattern.test(inputEmail);
    setIsValidEmail(isValid);
  };

  const handlePhoneChange = (e) => {
    const inputPhone = e.target.value;
    const isValid = inputPhone.startsWith('+61');

    setIsValidPhone(isValid);

    if (isValid) {
      setPhone(inputPhone);
    }
  };

  return (
    <div id='sfdforms' fluid className="formscontainer" data-aos='fade-up'>
      <p className='formsp'>Have Some Questions?</p>
      <h1 className='formsh'>ASK NOW</h1>
      <Form className="form" data-aos='fade-up' onSubmit={handleSubmit}>
        <Form.Field data-aos='fade-up'>
          <label>Name</label>
          <input 
            placeholder="Enter your Name"
            onChange={(e) => setName(e.target.value)}
            style={{border:'2px solid #BE9874'}}
          />
        </Form.Field>
        <Form.Field data-aos='fade-up'>
          <label>Age</label>
          <input
            type="number"
            placeholder="Enter your Age"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            style={{border:'2px solid #BE9874'}}
          />
        </Form.Field>
        <Form.Field data-aos='fade-up'>
          <label>Gender</label>
          <Form.Group inline>
            <Radio
              label="Male"
              value="Male"
              checked={gender === 'Male'}
              onChange={() => setGender('Male')}
            />
            <Radio
              label="Female"
              value="Female"
              checked={gender === 'Female'}
              onChange={() => setGender('Female')}
            />
            <Radio
              label="Other"
              value="Other"
              checked={gender === 'Other'}
              onChange={() => setGender('Other')}
            />
          </Form.Group>
        </Form.Field>
        <Form.Field data-aos='fade-up'>
          <label>Email Address</label>
          <input
            type="email"
            placeholder="Enter your Email Address"
            value={email}
            onChange={handleEmailChange}
            style={{
              border: isValidEmail ? '2px solid #BE9874' : '2px solid red',
            }}
          />
          {!isValidEmail && (
            <div style={{ color: 'red' }}>Please enter a valid email address.</div>
          )}
        </Form.Field>
        <Form.Field data-aos='fade-up'>
          <label>Phone Number</label>
          <input
            type='tel'
            placeholder="Enter your Phone Number (e.g., +61123456789)"
            value={phone}
            onChange={handlePhoneChange}
            style={{
              border: isValidPhone ? '2px solid #BE9874' : '2px solid red',
            }}
          />
          {!isValidPhone && (
            <div style={{ color: 'red' }}>Please enter a valid phone number that starts with '+61' (e.g., +61123456789).</div>
          )}
        </Form.Field>
        <Form.Field data-aos='fade-up'>
          <label>Query</label>
          <textarea 
            placeholder="Enter your answer"
            onChange={(e) => setQuery(e.target.value)}
            style={{border:'2px solid #BE9874'}}
          />
        </Form.Field>
        <Button color='brown' type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
}

export default Spforms;
