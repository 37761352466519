import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../css/Spoffers.css';

const Spoffers = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true, // Animations will only occur once
      mirror: false, // Animation will not be mirrored when scrolling down
    }); // Initialize AOS with a default duration of 1000ms
  }, []);

  return (
    <div className='offerr'>
      <div className='offerswrap'>
        <div className='offersgrid'>
          <div className='offerscontent' data-aos='fade-up'>
            <span className='bestprices'>Best and Afordable</span>
            <h1>How Much Do Dental Implants Cost ?</h1>
            <p>The table provided below showcases the average cost of dental implants, encompassing the dental implant cost in Sydney, Australia. The price of tooth implants varies depending on the complexity of your specific case. It is important to note that this table is to be used as a general pricing guide, as each dental implant case is unique, and the cost may vary depending on various factors.
            </p>
          </div>

          {/* <div className='spoffercards'>
            <OffersCard
              heading="Family Package"
              subheading="2 Adults and 2 Kids"
              price="$200"
              description={[
                'Non consequat aute veniam',
                'Non consequat aute veniam',
              ]}
              data-aos='fade-up'
            />
            <OffersCard
              heading="Family Package"
              subheading="2 Adults and 2 Kids"
              price="$200"
              description={[
                'Non consequat aute veniam',
                'Non consequat aute veniam',
              ]}
              data-aos='fade-up'
            />
            <OffersCard
              heading="Family Package"
              subheading="2 Adults and 2 Kids"
              price="$200"
              description={[
                'Non consequat aute veniam',
                'Non consequat aute veniam',
              ]}
              data-aos='fade-up'
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Spoffers;
