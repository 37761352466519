import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import img1 from '../images/sp4.webp';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../css/Spcarousel.css';
import { Parallax, Pagination, Navigation } from 'swiper/modules';

export default function App() {
    return (
        <>
            <p className='spcp'>
                Implants for Every Need
            </p>
            <h1 className='spch1'>
                Types of Dental Implants
            </h1>
            <Swiper
                style={{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                }}
                speed={1000}
                parallax={true}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Parallax, Pagination, Navigation]}
                className="mySwiper"
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
            >
                <SwiperSlide>
                    <div className='spctext'>
                        <div
                            className="parallax-bg"
                            style={{ backgroundImage: `url(${img1})`, backgroundPosition: 'center' }}
                            data-swiper-parallax="-23%"
                        ></div>
                        <div className="title" data-swiper-parallax="-300">
                            <h1>Endosseous Implants</h1>
                        </div>
                        <div className="text" data-swiper-parallax="-100">
                            <p>
                                These are placed within the bone and are typically shaped like a screw or cylinder. They’re the most popular option, however, the bone must have enough depth and width to be successful.
                            </p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='spctext'>
                        <div
                            className="parallax-bg"
                            style={{ backgroundImage: `url(${img1})`, backgroundPosition: 'center' }}
                            data-swiper-parallax="-23%"
                        ></div>
                        <div className="title" data-swiper-parallax="-300">
                            <h1>Subperiosteal Implants</h1>
                        </div>
                        <div className="text" data-swiper-parallax="-100">
                            <p>
                                These implants are placed on top of the bone, but underneath the gum line. They involve a metal frame that works well if the patient doesn’t have deep enough bone.
                            </p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='spctext'>
                        <div
                            className="parallax-bg"
                            style={{ backgroundImage: `url(${img1})`, backgroundPosition: 'center' }}
                            data-swiper-parallax="-23%"
                        ></div>
                        <div className="title" data-swiper-parallax="-300">
                            <h1>U-shaped Implants</h1>
                        </div>
                        <div className="text" data-swiper-parallax="-100">
                            <p>
                                A metal pin or U-shaped frame is passed through the jawbone and into the gum tissue for these types of implants.
                            </p>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    );
}
