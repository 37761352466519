import React, { useEffect, useState } from 'react';
import '../css/Home.css';
import '../css/Mainbutton.css';
import Homebottom from './Homebottom';
import About from './About';
import Services from './Services';
import After from './After';
import Discover from './Discover';
import Offers from './Offers';
import PaymentPlan from './Paymentplan';
import Tabs from './Tabs';
import AdvancedTechnology from './AdvancedTechnology';
import Spforms from './Spform';
import Dream from './Dream';
import Abovef from './Abovef';
import { SlCalender } from 'react-icons/sl';
import { BiPhoneCall } from 'react-icons/bi';
import img1 from '../images/dentalhover2.webp'
import bg from '../images/Frame24.png'

const Home = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>

      <div className='homencontainer'>

        <div className='homencontainergrid'>
          <div className='homeng1'>
            <div className='homeh'>
              <h1>Spring Farm Dental</h1>
            </div>

          </div>
          <div className='homeng2'>

          </div>
        </div>

      </div>


      <div className='homenimagecontainer'>
        <img src={bg}></img>
      </div>
      <div className='specialisedserviceswrap1'>
        <div className='specialisedserviceswrap'>
          <div className='specialisedservices'>

          <div className='homecardz'>
              <div>
                <img src={img1}></img>
              </div>
              <div>
                <h2>Veneers</h2>
              </div>
            </div>

            <div className='homecardz'>
              <div>
                <img src={img1}></img>
              </div>
              <div>
                <h2>Check up and Clean</h2>
              </div>
            </div>

            <div className='homecardz'>
              <div>
                <img src={img1}></img>
              </div>
              <div>
                <h2>Invisalign</h2>
              </div>
            </div>
           

            <div className='homecardz'>
              <div>
                <img src={img1}></img>
              </div>
              <div>
                <h2>Emergency dental</h2>
              </div>
            </div>

           

            <div className='homecardz'>
              <div>
                <img src={img1}></img>
              </div>
              <div>
                <h2>Braces</h2>
              </div>
            </div>

            <div className='homecardz'>
              <div>
                <img src={img1}></img>
              </div>
              <div>
                <h2>Full mouth implants</h2>
              </div>
            </div>





          </div>
        </div>
      </div>

      {/* <div className='columncontentback'>

<h2>Specialized Services</h2>
<br></br>
<div className='columncontent'>

<div className='homeoptions' >
<h1>Check up and Clean</h1>
</div>

<div className='homeoptions'>
<h1>Veneers</h1>
</div>

<div className='homeoptions'>
<h1>Emergency dental</h1>
</div>

<div className='homeoptions'>
<h1>Invisalign</h1>
</div>

<div className='homeoptions'>
<h1>Braces</h1>
</div>

<div className='homeoptions'>
<h1>Full mouth implants</h1>
</div>

</div>
</div> */}
      <div className='homecontainer'>
      </div>
      <div className='homebut'>

        <a href='https://www.centaurportal.com/d4w/org-818/extended_search?sourceID=null'>
          <div className="button-borders">
            <button className="primary-button">
              BOOK AN APPOINTMENT
              &nbsp;<SlCalender /></button>
          </div>
        </a>

        <a href="tel://02 4603 1277">
          <div className="button-borders">
           <button className="primary-button">BOOK A CALL &nbsp;<BiPhoneCall /></button>
          </div>
        </a>
      </div>

      <Homebottom />
      <About />
      <Services />
      <After />
      <Discover />
      <Offers />
      <PaymentPlan />
      {isMobileView ? <AdvancedTechnology /> : <Tabs />}
      <Spforms />
      <Dream />
      <Abovef />
    </>
  );
}

export default Home;
