import React from 'react'
import '../css/Teethwhite.css'
import img1 from '../images/dentalcheck.webp'
import img2 from '../images/teethwhite1.webp'
import img3 from '../images/teethwhite2.webp'
import Faq from 'react-faq-component';
import { RiQuestionAnswerLine } from "react-icons/ri";
import Abovef from './Abovef';
import '../css/Servicecomponent.css'
import Serviceop from './Serviceop'
import Spforms from './Spform'

const data = {

    rows: [
        {
            title: <h1 className="titleheading">Q. What is teeth whitening, and how does it work?</h1>,
            content: (
                <span className="Spancon">
                    Teeth whitening is a cosmetic dental procedure aimed at lightening the color of your teeth. It works by using bleaching agents, such as hydrogen peroxide or carbamide peroxide, to break down and remove surface stains and deep-seated discoloration within the tooth enamel. This process can help restore a brighter and whiter smile.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">Q. Is teeth whitening safe?</h1>,
            content: (
                <span className="Spancon">
                    Yes, teeth whitening is generally considered safe when performed by a trained dental professional or when using over-the-counter whitening products as directed. However, it's essential to follow instructions carefully and consult with your dentist if you have any concerns or underlying dental issues to ensure your safety during the process.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">Q. Are there any side effects associated with teeth whitening?</h1>,
            content: (
                <span className="Spancon">
                    Some individuals may experience temporary side effects, including tooth sensitivity or gum irritation, during or after teeth whitening. These side effects usually subside within a few days to a week. Using a desensitizing toothpaste and following recommended guidelines can help minimize these issues.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">Q. How long does teeth whitening last, and can I maintain the results?</h1>,
            content: (
                <span className="Spancon">
                    The longevity of teeth whitening results varies from person to person and depends on factors such as diet, oral hygiene, and lifestyle choices. Typically, teeth whitening effects can last from several months to a couple of years. To maintain your results, it's essential to practice good oral hygiene, limit consumption of staining foods and drinks, and consider touch-up treatments as needed.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">Q. Can anyone get their teeth whitened, or are there restrictions?</h1>,
            content: (
                <span className="Spancon">
                    While teeth whitening is generally suitable for many people, it may not be recommended for individuals with certain dental conditions, such as tooth decay, gum disease, or extremely sensitive teeth. Pregnant or nursing women and children may also need to avoid teeth whitening treatments. It's crucial to consult with a dentist to determine if teeth whitening is suitable for your specific dental situation.
                </span>
            ),
        },
        {
            title: <h1 className="titleheading">Q. What are the different teeth whitening options available?</h1>,
            content: (
                <span className="Spancon">
                    There are several teeth whitening options, including:
                    <ul>
                        <li> In-office professional whitening: Performed by a dentist and provides quick and dramatic results.</li>
                        <li> At-home professional whitening: Dentist-prescribed kits with custom trays for at-home use.</li>
                        <li> Over-the-counter whitening products: Such as whitening strips, gels, and toothpaste, available without a prescription.</li>
                    </ul>
                    The choice of method depends on your preferences, budget, and the level of whitening you desire. Consulting with a dentist can help you decide the best approach for your needs.
                </span>
            ),
        },
    ],
};

const styles = {
    bgColor: 'white',
    titleTextSize: '3rem',
    rowTitleColor: "blue",
    rowContentColor: 'grey',
    arrowColor: "black",
    transitionDuration: "0.5s",
    timingFunc: "ease",
    rowContentColor: 'grey',
    rowContentPaddingTop: '4%'
};

const config = {
    animate: true,
};

const Teethwhite = () => {
    return (
        <>
          <div className='spbanner'>
            <img className='spimg' src={img1} alt="Teeth Whitening Image" />
          </div>
          <div className='spbbwrap'>
            <div className='spbbottom' data-aos='fade-up'>
              <span>Home {'>'} Services {'>'} Cosmetic Dentistry{'>'} Teeth Whitening </span>
              <h1>TEETH WHITENING</h1>
              <p>Your smile is a source of joy that's right under your nose. At Spring Farm Dental, we understand the importance of a captivating smile. It's often the first thing people notice, and we appreciate the lasting impact of initial impressions.</p>
              <p>If you're looking to enhance your smile, we're here to help you regain its radiance with our swift and effective teeth whitening services.</p>
            </div>
          </div>
      
          <div className='veneersgrid'>
            <div className='veneerscontent'>
              <h1>Discover What Your Smile Reveals</h1>
              <p>Many Australians experience smile-related concerns. Issues like chipped or missing teeth, dental fillings, or teeth discoloration can lead to self-consciousness about one's appearance. If you find yourself holding back your smile, it might be the right time to consider teeth whitening.</p>
              <p>We offer affordable teeth whitening services in Spring Farm. Achieving a whiter, brighter smile and regaining your confidence has never been more accessible with us. Our in-office teeth whitening treatment is entirely safe and effective, ensuring it won't harm your teeth.</p>
            </div>
            <div className='veneersimg'>
              <img src={img2} alt="Teeth Whitening Procedure" />
            </div>
          </div>
      
          <div className='veneersgrid'>
            <div className='veneersimg1'>
              <img src={img3} alt="Smiling Patient" />
            </div>
            <div className='veneerscontent'>
              <h1>Is Teeth Whitening Right for You?</h1>
              <p>At Spring Farm Dental, we consider teeth whitening as an act of self-care. While it's seen as a cosmetic procedure with no functional benefits, we believe that enhancing your smile can have a positive impact on your emotional well-being and overall health. It's a reasonable choice to make for yourself.</p>
            </div>
          </div>
      
          <div className='teethwhitewrap'>
            <div className='teethwhitecon'>
              <h1>The Benefits of Professional Teeth Whitening</h1>
              <p>Absolutely! Opting for professional teeth whitening at the dentist is highly worthwhile for several reasons. We use high-quality materials in precisely balanced quantities, ensuring safety and effectiveness. This distinguishes us from over-the-counter teeth-whitening products, which are often less effective and abrasive.</p>
              <p>There are various reasons that can cause teeth discoloration, but four primary signs indicate the need for professional teeth whitening:</p>
              <ul>
                <li>Your smile no longer appears as bright or white as it used to.</li>
                <li>Your mouth feels dry after brushing or flossing.</li>
                <li>You notice dark stains on your teeth.</li>
                <li>Yellow tissue is visible beneath your gums, potentially indicating plaque buildup.</li>
              </ul>
              <div className='teethp'>
                <p>If you are experiencing any of the above concerns, it's advisable to schedule a consultation with our dentists to determine the most suitable course of action.</p>
              </div>
            </div>
          </div>
      
          <div className="servicecomp">
            <h1 className="faqheading">Unlocking the Secrets of Your Smile</h1>
            <h1 className="faqheadingd">Frequently Asked Questions 	&nbsp; <RiQuestionAnswerLine /> </h1>
            <Faq
              data={data}
              styles={styles}
              config={config} />
          </div>
      
          <div className='dentalgrid'>
            <div>
              <Serviceop />
            </div>
            <div>
              <Spforms />
            </div>
          </div>
          <Abovef />
        </>
      );
      
      
}

export default Teethwhite