import React from 'react'
import '../css/Contactpage.css'
import Contactcard from './Contactcard'
import Contactcard1 from './Contactcard1'
import Book from './Book'
import Spforms from './Spform'

const Contactpage = () => {
  return (
    <>
    <div className='contacbanner' >
      <h1>Contact Us</h1>
      <p>"Smile Bright, Reach Out Today!"
</p>
    </div>
    <Contactcard/>
    <div>
      <Spforms/>
    </div>
    <Contactcard1/>
    <Book/>
    </>
  )
}
export default Contactpage